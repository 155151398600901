import React, { useState, useEffect, useContext, useRef } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
/*global google*/

import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  TextField,
  Modal,
  InputAdornment,
  Paper,
  Box,
  IconButton,
} from "@material-ui/core";
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import { Button, Link, useMediaQuery } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import AlertDialog from "./AlertDialog";
import GoogleMapsAutoComplete from "./GoogleMapsAutoComplete";
import CloseIcon from '@mui/icons-material/Close';

import * as geolib from "geolib";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    // padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "@media(max-width: 1850px)": {
      width: "1900px",
    },
  },
  headerCell: {
    background: "#01aac1",
    color: "white",
    padding: theme.spacing(2),
    border: "1px solid white",
    fontWeight: "bold",
    maxWidth: 200,
  },
  tableCell: {
    background: "white",
    color: "#01aac1",
    padding: theme.spacing(2),
    maxWidth: 200,
    border: "1px solid white",
  },
  routeCell: {
    maxWidth: 500,
  },
  legendContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: "#f5f5f5",
    borderRadius: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  legendIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },
  legendLine: {
    width: 20, // Adjust as needed
    height: 4,
    backgroundColor: "black", // Adjust line color as needed
    marginRight: theme.spacing(1),
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    'media(max-width:450px)':{
      width:"90%"
    }
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
}));

const Map = withGoogleMap((props) => {
  const { api } = useContext(FirebaseContext);
  const { getEstimate, clearEstimate, addBooking, clearBooking } =
    api;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const rootRef = useRef(null);
  const userdata = useSelector(state => state.usersdata);
  const routes = useSelector(state => state.routes);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedPolyline, setSelectedPolyline] = useState(null);
  const [isHovered, setHovered] = useState(null);
  const [estimateRequested, setEstimateRequested] = useState({
    loading: false,
    id: null,
  });

  const [availableRoutes,setAvailableRoutes] = useState([])
  useEffect(()=>{
    if(routes.routes&&routes.routes.length>0){
      const filteredRoutes = routes.routes.filter(e=>e.isActive).map(e=>{
            return {lineSegment:[e.pickup?.coords,e.drop?.coords],color:"#147eb8",...e}
      })
      setAvailableRoutes(filteredRoutes)
    }
},[routes])



  useEffect(() => {
    setSelectedMarker(null);
    setSelectedPolyline(null);
    setHovered(null);
    setEstimateRequested({ loading: false, id: null });
  }, [props.ActiveCarrier]);
  useEffect(() => {
    if ((props.dropAddress && props.pickupAddress) || props.selectedDate) {
      setSelectedMarker(null);
      setSelectedPolyline(null);
      setHovered(null);
      setEstimateRequested({ loading: false, id: null });
    }
  }, [
    props.dropAddress,
    props.pickupAddress,
    props.selectedDate,
    props.boardType,
  ]);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setSelectedPolyline(null); // Close any open polyline info window
    setHovered(marker);
  };

  const handlePolylineClick = (polyline) => {
    setSelectedMarker(null); // Close any open marker info window
    setSelectedPolyline(polyline);
    setHovered(polyline);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
    setSelectedPolyline(null);
    setHovered(null);
    setEstimateRequested({ loading: false, id: null });
  };

  const calculateMiddleCoordinate = (point1, point2) => {
    return {
      lat: (point1.lat + point2.lat) / 2,
      lng: (point1.lng + point2.lng) / 2,
    };
  };

  const legendItems = [
    {
      label: "Available Capacity",
      color: "#5892c6",
      icon: "https://cdn-icons-png.flaticon.com/512/6492/6492616.png",
    },
    { label: "Future Capacity", color: "#748f92" },
    { label: "Preferred Lane", color: "#01aac1" },
  ];

  const auth = useSelector((state) => state.auth);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [role, setRole] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    loadDimensions: "",
    loadWeight:"",
    pickUpInstructions: "",
    deliveryInstructions: "",
    shipperOffer: "",
    pickupDate: moment(new Date()).format('YYYY-MM-DD'),
    dropDate: moment(new Date()).format('YYYY-MM-DD'),
    commodity: "",
    loadIdentification: "",
  });

  const [showDettail, setShowDetail]=useState(false)

  const matches = useMediaQuery('(max-width:400px)');
  const showedstyles={
    padding: showDettail?"20px":0,
    width: showDettail? matches ? "100%" :"350px":0
  }
  const [selectedTrailerDetails, setSelectedTrailerDetails] = useState(null);

  // handle input change for InstructionData and Load rate
const [deliveryPersonPhoneWithOutPlus, setDeliveryPersonPhoneWithOutPlus] = useState('')
const handleChange = (e) => {
    if (e.target.name === "optionIndex") {
      setInstructionData({
        ...instructionData,
        optionIndex: parseInt(e.target.value),
        optionSelected: selectedTrailerDetails.options[e.target.value],
      });
    } else {
      if (e.target.name === 'deliveryPersonPhone') {
        setDeliveryPersonPhoneWithOutPlus(e.target.value.replace(/[^0-9]/g, ''))
        const deliveryPersonPhoneValue = '+' + e.target.value.replace(/[^0-9]/g, '');
        setInstructionData({ ...instructionData, [e.target.name]: deliveryPersonPhoneValue});
      }
      else{
          setInstructionData({ ...instructionData, [e.target.name]: e.target.value });
        }
    }
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateModalStatus(true);
    }
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile);
    }
  }, [estimatedata.estimate, auth.info, estimateRequested]);

  // Sends to booking screen after a booking has been posted
  useEffect(() => {
    if (bookingdata.booking && bookingdata.booking.mainData.status) {
      dispatch(clearBooking());
      props.history.push("/bookings");
      setEstimateModalStatus(false);
      dispatch(clearEstimate());
      setEstimateRequested({ loading: false, id: null });
    }
  }, [bookingdata.booking, props.history, clearBooking, dispatch]);

  let [BookFleetAdmin, HandleBookFleetAdmin] = useState(null);

  const handleGetEstimate = (tableData) => {
    setEstimateRequested({ loading: true, id: tableData.id });
    HandleBookFleetAdmin(tableData?.basicData.fleetadmin);
    if (auth.info) {
      if (auth.info.profile.usertype === "shipper" || auth.info?.profile.brokerId) {
        if (
          auth.info.profile.email === " " ||
          auth.info.profile.firstName === " " ||
          auth.info.profile.lastName === " "
        ) {
          setCommonAlert({ open: true, msg: t("profile_incomplete") });
          setEstimateRequested({ loading: false, id: null });
        } else {
          if (props.pickupAddress && props.dropAddress && props.selectedDate) {
            const { pickupAddress, dropAddress, selectedDate } = props;

            if (new Date().getTime() > new Date(selectedDate).getTime()) {
              setCommonAlert({
                open: true,
                msg: "Pickup Date Should  future dates.",
              });
              setEstimateRequested({ loading: false, id: null });
            } else if (!tableData?.canBeBooked) {
              // empty route request error
              setCommonAlert({
                open: true,
                msg: t("not_within_driver_pref"),
              });
              setEstimateRequested({ loading: false, id: null });
            } else {
              const directionService =
                new window.google.maps.DirectionsService();
              directionService.route(
                {
                  origin: new window.google.maps.LatLng(
                    pickupAddress.coords.lat,
                    pickupAddress.coords.lng
                  ),
                  destination: new window.google.maps.LatLng(
                    dropAddress.coords.lat,
                    dropAddress.coords.lng
                  ),
                  travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                    const route = {
                      distance_in_km:
                        result.routes[0].legs[0].distance.value / 1000,
                      time_in_secs: result.routes[0].legs[0].duration.value,
                      polylinePoints: result.routes[0].overview_polyline,
                    };
                    let estimateRequest = {
                      pickup: pickupAddress,
                      drop: dropAddress,
                      routeDetails: route,
                      post_request: tableData?.basicData
                        ? tableData?.basicData
                        : null,
                    };

                    dispatch(getEstimate(estimateRequest));
                  } else {
                    setCommonAlert({
                      open: true,
                      msg: t("place_to_coords_error"),
                    });
                    setEstimateRequested({ loading: false, id: null });
                  }
                }
              );
            }
          } else {
              setEstimateRequested({ loading: false, id: null });
              if(!props.pickupAddress){
                setCommonAlert({
                  open: true,
                  msg: 'Please Select Pick up Address',
                });
              }
              else if(!props.dropAddress){
                setCommonAlert({
                  open: true,
                  msg: 'Please Select Drop Address',
                });
              }
              else if(!props.selectedDate){
                setCommonAlert({
                  open: true,
                  msg: 'Please Select Pickup Date',
                });
              }
          }
        }
      }
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
      setEstimateRequested({ loading: false, id: null });
    }
  };

  // EstimateModalClose
  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested({ loading: false, id: null });
  };
  // EstimateErrorClose
  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested({ loading: false, id: null });
  };

  // BookingErrorClose
  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested({ loading: false, id: null });
  };

  // CommonAlert
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const [phoneAlert, setPhoneAlert] = useState({ open: false, msg: "" });

  // Close PhoneAlert
  const handlePhoneAlertClose = (e) => {
    e.preventDefault();
    setPhoneAlert({ open: false, msg: "" });
  };

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}
 
useEffect(()=>{
  setInstructionData({...instructionData,pickupDate:props.selectedDate})
},[props.selectedDate])


// Sends to edit profile if phone details not available
  const handlePhoneAlertGo = (e) => {
    e.preventDefault();
    props.history.push("/profile");
  };
  const [bookProgress, setBookProgress] = useState(false);

  const confirmBooking = (e) => {
    e.preventDefault();

    const regx1 = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    if(new Date(instructionData.dropDate).getTime() < new Date().getTime() || new Date(instructionData.pickupDate).getTime() < new Date().getTime()){
      setCommonAlert({ open: true, msg: "The Drop Date and Pickup Date cannot be Past Dates." })
      return
    }
   else if(new Date(instructionData.dropDate).getTime() <= new Date(instructionData.pickupDate).getTime()){
      setCommonAlert({ open: true, msg: "The Drop Date cannot be earlier than the Pickup Date." })
      return
    }

   else if (!regx1.test(instructionData.deliveryPersonPhone)) {
      setCommonAlert({ open: true, msg: "Please use valid phone" });
    } 
  //   else if(estimatedata.estimate.post_request?.availableWeight && Number(instructionData.loadWeight) > Number(estimatedata.estimate.post_request?.availableWeight)){
  //     setCommonAlert({ open: true, msg: `The weight of ${estimatedata.estimate.post_request?.availableWeight} Kg is the maximum this driver can handle.` });   
  // }
    else if (
      instructionData.deliveryPersonPhone &&
      instructionData.deliveryPersonPhone.length > 6 &&
      instructionData.pickUpInstructions &&
      instructionData.deliveryInstructions &&
      instructionData.shipperOffer &&
      instructionData.loadDimensions &&
      instructionData.loadWeight 
    ) {
      setBookProgress(true);
      //setEstimateModalStatus(false);
      let bookingObject = {
        approved: false,
        fleetadmin: BookFleetAdmin,
        pickup: props.pickupAddress,
        drop: props.dropAddress,
        trailerDetails: selectedTrailerDetails ? selectedTrailerDetails : null,
        userDetails: auth.info?.profile?.usertype==='shipper'?{id:auth.info.uid,...auth.info?.profile}:giveIdThenIwillGiveYouData(auth.info?.profile?.brokerId),
        estimate: estimatedata.estimate,
        instructionData: instructionData,
        bookLater: true,
        settings: settings,
        booking_type_admin: false,
        post_request: estimatedata.estimate.post_request
          ? estimatedata.estimate.post_request
          : null,
      };

      dispatch(addBooking(bookingObject))
        .then(() => {
          setBookProgress(false);
          setEstimateModalStatus(false);
          dispatch(clearEstimate());
          setEstimateRequested({ loading: false, id: null });
          // window.location.href="/bookings"
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      setCommonAlert({ open: true, msg: t("please_complete_form") });
    }
  };

  //when user comes from truck board page
  const [openOriginAndDestinationModal, setOpenOriginAndDestinationPage] = useState({open:false,route:null})
  const [error,setError]=useState('')
  
  const handleOriginAndDestinationModal=(route)=>{
       setOpenOriginAndDestinationPage({open:true, route:route})
  }

  function calculateTotalDistance(route) {
    return geolib.getDistance(route[0], route[1]);
  }

  const handleCheckOriginAndDestinationOnRoute=()=>{
    setError("")
    if(!props.pickupAddress || !props.dropAddress){
      return setError("Please select pick up and drop address")
    }
    if(openOriginAndDestinationModal.route){
      const pickupAddressObject = {
        latitude: props.pickupAddress.coords.lat,
        longitude: props.pickupAddress.coords.lng,
      };
      const dropAddressObject = {
        latitude: props.dropAddress.coords.lat,
        longitude: props.dropAddress.coords.lng,
      };

      if( openOriginAndDestinationModal.route?.type === "Available Capacity" && openOriginAndDestinationModal?.route?.travelRadius){
          let travelRadius = Number(openOriginAndDestinationModal?.route?.travelRadius) * 1609.34;
            const hostLocation = {
              latitude: openOriginAndDestinationModal.route.lat,
              longitude: openOriginAndDestinationModal.route.lng,
            };
  
            const DistancePickFromLocation = calculateTotalDistance([
              hostLocation,
              pickupAddressObject,
            ]);
            if (travelRadius >= DistancePickFromLocation){
              handleGetEstimate(openOriginAndDestinationModal.route)
            }
            else  setError('This PickUp and DropOff is not along the route')

      }
      else if( openOriginAndDestinationModal.route?.type === "Available Capacity" && !openOriginAndDestinationModal?.route?.travelRadius){
        handleGetEstimate(openOriginAndDestinationModal.route)
      }
      else if(openOriginAndDestinationModal.route.lineSegment && openOriginAndDestinationModal.route.lineSegment.length>0){
          const lineSegments = [
            {
              latitude: openOriginAndDestinationModal.route.lineSegment[0].lat,
              longitude: openOriginAndDestinationModal.route.lineSegment[0].lng,
            },
            {
              latitude: openOriginAndDestinationModal.route.lineSegment[1].lat,
              longitude: openOriginAndDestinationModal.route.lineSegment[1].lng,
            },
          ]

          let tracking_data=[{
              id: openOriginAndDestinationModal.route.id,
              address: lineSegments,
            }]
            
    
            if(props.checkIfPickUpAndDropIsAlongTheRoutes(pickupAddressObject,dropAddressObject,tracking_data)){
                handleGetEstimate(openOriginAndDestinationModal.route)
            }
            else{
              setError('This PickUp and DropOff is not along the route')
            }

      }
      else{
        setError('This PickUp and DropOff is not along the route')
      }

    }
    else{
      setError("Route Not Found")
    }
  }

  const handlCloseOriginAndDestinationModal =()=>{
      props.setPickupAddress(null)
      props.setDropAddress(null)
      setOpenOriginAndDestinationPage({open:false,route:null})
  }

  useEffect(()=>{
      if(selectedMarker || selectedPolyline){
        setShowDetail(true)
      }
      else  setShowDetail(false)

  },[selectedMarker,selectedPolyline])

  const closeDetailBox=()=>{
    setShowDetail(false)
    setSelectedMarker(null)
    setSelectedPolyline(null)
  }

  const trailertypes = useSelector((state) => state.trailertypes);

  const trailerDatasOfDriiver  = (trailertype)=>{

    if(!trailertype)  return {name:"Disclosed"}
    if(!trailertypes?.trailers) return {name:'Loading ...'}
     const driverTrailerType  =  trailertypes.trailers.filter(e=>{
            return trailertype === e.name
      })
     if(driverTrailerType.length ===  0)  return{name: "Not Registered"}
     
     return{
       imageUrl: driverTrailerType[0].image,
       name:driverTrailerType[0].name
     }
}

const isSpecialAdmin=()=>{
     
  return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
}

const [dispatchersOnSelectedRoute,setDispatchersOnSelectedRoute] = useState(null)
useEffect(()=>{
  if(userdata?.users && userdata?.users?.length>0&&selectedPolyline){
      const dispatchers = userdata.users.filter(e=>{
          return e?.usertype === 'dispatcher' && e?.carrierId && e?.workingRoute
                && e?.workingRoute?.includes(selectedPolyline.identification)
      })
    
    
      setDispatchersOnSelectedRoute(dispatchers)

  }
},[selectedPolyline,userdata?.users])


return (
   <>
    <div
      style={{
        display: "flex",
        paddingTop: "5px",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
      }}
    >

    <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openOriginAndDestinationModal.open}
        onClose={handlCloseOriginAndDestinationModal}
        className={classes.modal}
        container={() => rootRef.current}
        style={{
          overflow: "auto",
          height: "80vh",
          paddingTop: "220px",
          top: 10,
        }}
      >
          <Grid
            container
            spacing={1}
            className={classes.paper}
            style={{ flexDirection:"column",alignItems:"center" ,gap:"20px"}}
          >
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",gap:"10px"}}>
                    <div style={{ width: "160px" }}>
                        <Paper>
                            <GoogleMapsAutoComplete
                            placeholder={t("pickup_location")}
                            variant={"filled"}
                            value={props.pickupAddress}
                            onChange={(value) => {
                              props.setPickupAddress(value);
                            }}
                          />
                        </Paper>
                      </div>

                      <div style={{ width: "160px" }}>
                        <Paper>
                            <GoogleMapsAutoComplete
                            placeholder={t("drop_location")}
                            variant={"filled"}
                            value={props.dropAddress}
                            onChange={(value) => {
                              props.setDropAddress(value);
                            }}
                          />
                        </Paper>
                      </div>
                </Box>

                {!props.selectedDate&&<div style={{color:"#fca503",margin:"10px 0 10px 0"}}>Please Select Pickup Date</div>}

                {error&&<div style={{color:"red",margin:"10px 0 10px 0"}}>{error}</div>}

                  <Button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: 100,
                        height: 50,
                        color: "white",
                        backgroundColor: "#01aac1",
                        borderRadius: 5,
                        fontSize: 10,
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                      onClick={() => handleCheckOriginAndDestinationOnRoute()}
                    >
                      <Typography variant="subtitle2">Check</Typography>
                    </Button>

          </Grid>

      </Modal>


      { !isSpecialAdmin() &&
      <>
      <div style={{ margin: "5px 0 0 3px", color: "#01aac1" }}>
        Click on a route or Truck to See Details
      </div>

      <div
        style={{
          width: "100%",
          overflow: "auto",
          display: "flex",
          marginTop: "6px",
          flexDirection: "column",
          alignItems: "center",
          flexWrap: "wrap",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        <div >
          <Grid container direction="row">
            {legendItems.map((item, index) => (
              <Grid key={index} item className={classes.legendItem}>
                {item.icon && (
                  <img
                    src={item.icon}
                    alt="legend icon"
                    className={classes.legendIcon}
                  />
                )}
                {!item.icon && (
                  <span
                    className={classes.legendLine}
                    style={{ backgroundColor: item.color }}
                  />
                )}
                <span style={{ color: item.color }}>{item.label}</span>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      </>
}

      <GoogleMap defaultZoom={5} center={props.mapcenter}>
          {props.locations.map((marker) => {
            return (
              <div key={marker.id}>
                
                {marker.lat && marker.lng && (
                  <Marker
                    position={{ lat: marker.lat, lng: marker.lng }}
                    icon={{
                      url:  marker.carImage,
                      scaledSize:
                        isHovered && isHovered.id === marker.id
                          ? new google.maps.Size(70, 70)
                          : new google.maps.Size(40, 40),
                    }}
                    onClick={() => handleMarkerClick(marker)}
                  >
                    {selectedMarker && selectedMarker.id === marker.id && (
                      <InfoWindow onCloseClick={handleInfoWindowClose}>
                        <div style={{ fontWeight: "700" }}>
                          {props.type && (
                            <div
                              style={{
                                marginBottom: "px",
                                textAlign: "center",
                                color:"black"
                              }}
                            >
                              {marker?.drivername || marker?.name}
                            </div>
                          )}
                          {selectedMarker.travelRadius ? (
                            <div
                              style={{ fontWeight: "500", textAlign: "center",fontSize:"11px" }}
                            >
                              Available Anywhere within{" "}
                              {selectedMarker.travelRadius} Mile radius
                            </div>
                          ) : (
                            <div
                              style={{ fontWeight: "500", textAlign: "center" }}
                            >
                              Available Anywhere
                            </div>
                          )}

                        <div
                              style={{
                                textAlign: "center",
                                fontSize:"12px",
                                fontWeight:"300"
                              }}
                            >
                              {marker?.type}
                            </div>

                        </div>
                      </InfoWindow>
                    )}

                  </Marker>
                )}
                
                { !isSpecialAdmin()&&marker.lineSegment && marker.lineSegment.length > 0 && (
                  <div>
                    <Polyline
                      path={marker.lineSegment}
                      options={{
                        strokeColor:
                          isHovered && isHovered.id === marker.id
                            ? "#31d9e4"
                            : marker.color,
                        strokeOpacity: 1,
                        strokeWeight:
                          isHovered && isHovered.id === marker.id ? 15 : 6,
                      }}
                      onClick={() => handlePolylineClick(marker)}
                    />

                    {selectedPolyline && selectedPolyline.id === marker.id && (
                      <InfoWindow
                        position={calculateMiddleCoordinate(
                          marker.lineSegment[0],
                          marker.lineSegment[marker.lineSegment.length - 1]
                        )}
                        onCloseClick={handleInfoWindowClose}
                      >
                        <div style={{ fontWeight: "700" }}>
                          {props.type &&( marker.drivername || marker?.name)}
                            <div
                              style={{
                                textAlign: "center",
                                fontSize:"12px",
                                fontWeight:"300",
                                marginTop: "4px",
                              }}
                            >
                                {selectedPolyline?.type}
                            </div>
                        </div>


                      </InfoWindow>
                    )}

                  
                    
                    {isHovered &&
                      isHovered.id === marker.id &&
                      (marker.type === "Empty Route" ||
                        marker.type === "Return Home Route") && (
                        <React.Fragment>
                          <Marker
                            position={marker.lineSegment[0]}
                            icon={{
                              url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                              scaledSize: new google.maps.Size(25, 25),
                            }}
                          />
                          <Marker
                            position={
                              marker.lineSegment[marker.lineSegment.length - 1]
                            }
                            icon={{
                              url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                              scaledSize: new google.maps.Size(25, 25),
                            }}
                          />
                        </React.Fragment>
                      )}
                    {isHovered &&
                      isHovered.id === marker.id &&
                      marker.type === "Preferred Lane" && (
                        <React.Fragment>
                          <Marker
                            position={marker.lineSegment[0]}
                            icon={{
                              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              scaledSize: new google.maps.Size(25, 25),
                            }}
                          />
                          <Marker
                            position={
                              marker.lineSegment[marker.lineSegment.length - 1]
                            }
                            icon={{
                              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              scaledSize: new google.maps.Size(25, 25),
                            }}
                          />
                        </React.Fragment>
                      )}
                  </div>
                )}

              </div>
            );
          })}
          
          {availableRoutes&&availableRoutes.length>0&&
              <>
                  {availableRoutes.map(route=>{
                     return(
                      <>
                         <Polyline
                          path={route.lineSegment}
                          options={{
                            strokeColor:
                              isHovered && isHovered.id === route.id
                                ? "#31d9e4"
                                : route.color,
                            strokeOpacity: 1,
                            strokeWeight:
                              isHovered && isHovered.id === route.id ? 15 : 6,
                          }}
                          onClick={() => handlePolylineClick(route)}
                        />
                      </>
                     )
                  })}
              </>
          }
      </GoogleMap>

      <Box sx={{position:"absolute", top:props.type==='dashboard'?auth.info?.profile?.usertype==='shipper'?"279px":"220px":"127px",
               right:"10px",zIndex:5,background:"white",
                padding:showedstyles.padding,borderRadius:"20px 20px 20px 20px",maxHeight:"64vh",overflow:"auto",
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                width:showedstyles.width,lineBreak:"anywhere",overflowX:"hidden",transition:'0.5s',
                scrollbarWidth: 'none',
                '@media(max-width:400px)':{right:0}
            }}>
        <div><IconButton onClick={closeDetailBox}><CloseIcon/></IconButton></div>

          <Typography style={{textAlign:"center",fontWeight:600,marginBottom:"10px"}} variant="h5">Route Detail</Typography>
         
         {selectedPolyline&&!selectedPolyline.name&&
              <Box> 
                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Truck Location (In Real-Time) </div>
                      </Box>
                      <Box style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                        {giveIdThenIwillGiveYouData(selectedPolyline?.basicData?.driver?.id)?.location?.add ? giveIdThenIwillGiveYouData(selectedPolyline?.basicData?.driver?.id)?.location?.add :  'Unknown'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Trailer Type </div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {trailerDatasOfDriiver(selectedPolyline.trailerType)?.imageUrl && 
                          <img  style={{width: 50}}  alt='CarImage' src={trailerDatasOfDriiver(selectedPolyline.trailerType)?.imageUrl}/>}
                         {trailerDatasOfDriiver(selectedPolyline.trailerType)?.name}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Vehicle Number</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {giveIdThenIwillGiveYouData(selectedPolyline?.basicData?.driver?.id)?.vehicleNumber||'Not Registered'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Route </div>
                      </Box>
                      <Box style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          <div style={{lineBreak:"anywhere",width:"100%" }}>
                               <span>{selectedPolyline?.cityOne}</span>
                              <span
                                style={{
                                  color: "red",
                                  paddingRight: 5,
                                  paddingLeft: 5,
                                }}
                              >
                                {selectedPolyline?.type === "Preferred Lane" ? (
                                  <MultipleStopIcon  style={{fontSize:"17px"}}/>
                                ) : (
                                  "-->"
                                )}
                              </span>
                              <span >{selectedPolyline?.cityTwo}</span>
                          </div>
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>  Route Type</div>
                      </Box>
                      <Box style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          {selectedPolyline?.type &&
                          selectedPolyline?.type === "Preferred Lane"
                          ? selectedPolyline.type
                          : "Future Capacity"}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Date (s)</div>
                      </Box>
                      <Box style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          {selectedPolyline?.routeDate}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Rate Per Mile</div>
                      </Box>
                      <Box style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                        {selectedPolyline?.basicData?.ratePerMile?`$${selectedPolyline?.basicData?.ratePerMile}/Mile`
                          :"Unknown"}
                      </Box>
                  </Box>
                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Loaded Weight</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {selectedPolyline?.basicData?.availableWeight?`${selectedPolyline?.basicData?.availableWeight} Kg`:"0 Kg"}

                      </Box>
                  </Box>

                  <Box style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      {(auth.info.profile.usertype === "shipper" || auth.info?.profile.brokerId)&& (
                        <Grid item xs={2} className={classes.tableCell}>
                          {estimateRequested.loading &&
                          estimateRequested?.id === selectedPolyline?.basicData?.id ? (
                            <CircularProgress size={25} />
                          ) : (
                            <Button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: 100,
                                height: 50,
                                color: "white",
                                backgroundColor: "#01aac1",
                                borderRadius: 5,
                                fontSize: 10,
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                              onClick={() => props.accessdFrom==='TruckBoard'?handleOriginAndDestinationModal(selectedPolyline):handleGetEstimate(selectedPolyline)}
                            >
                              <Typography variant="subtitle2">Book</Typography>
                            </Button>
                          )}
                        </Grid>
                      )}
                  </Box>


                
              </Box>
          }

       {selectedPolyline&&selectedPolyline.name&&
              <Box> 
                 <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>ID</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          {selectedPolyline.identification}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Pickup</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          {selectedPolyline.pickup.description}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Drop-off</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          {selectedPolyline.drop.description}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Agents</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {dispatchersOnSelectedRoute&&dispatchersOnSelectedRoute?.length>0 &&
                             <>
                                {dispatchersOnSelectedRoute.map(e=>{
                                   return <Box key={e.id}>
                                          <Link style={{textDecoration:"none"}} target='_blank' href={`/user/${e.id}?redirected=fleetmap`}>{e.firstName} {e.lastName}</Link>
                                   </Box>
                                })}
                             </>
                         
                         }
                      </Box>
                  </Box>

                
              </Box>
          }
          
          {selectedMarker && selectedMarker.currentLocation&&
              <Box> 
                  {!giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.driverActiveStatus?
                  <Typography style={{fontSize:11,  color:"red",marginBottom:2,textAlign:"center"}}>
                    Last seen {moment(giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.lastseen).fromNow()}
                </Typography>:""}

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> {giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.driverActiveStatus?'Real-Time Location':'Last Known Location'} </div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {selectedMarker?.basicData?.location?.add|| 'Unknown'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Vehicle Make</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.vehicleMake||'Not Registered'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Vehicle Model</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.vehicleModel||'Not Registered'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Vehicle Number</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.vehicleNumber||'Not Registered'}
                      </Box>
                  </Box>

                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Trailer Type </div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {trailerDatasOfDriiver(selectedMarker.trailerType)?.imageUrl && 
                          <img  style={{width: 50}}  alt='CarImage' src={trailerDatasOfDriiver(selectedMarker.trailerType)?.imageUrl}/>}
                         {trailerDatasOfDriiver(selectedMarker.trailerType)?.name}
                      </Box>
                  </Box>

                 

                  {!isSpecialAdmin() && <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Route </div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          <div style={{ }}>
                            {giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.location?.add ? giveIdThenIwillGiveYouData(selectedMarker?.basicData?.driver?.id)?.location?.add :  'Unknown'}
                            <span
                              style={{
                                color: "red",
                                width: "fit-content",
                                marginRight: 5,
                                marginLeft: 5,
                              }}
                            >
                              {">"}
                            </span>
                            Anywhere{" "}
                            {selectedMarker.travelRadius
                              ? "within " + selectedMarker.travelRadius + " mile radius"
                              : "within any mile radius"}
                          </div>
                      </Box>
                  </Box> }

                  {!isSpecialAdmin() && <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>  Route Type</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                          Available Capacity
                      </Box>
                  </Box> }

                  {!isSpecialAdmin() && <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Date (s)</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         Available Now
                      </Box>
                  </Box> }

                  {!isSpecialAdmin() && <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div> Rate Per Mile</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {selectedMarker?.basicData?.ratePerMile?`$${selectedMarker?.basicData?.ratePerMile}/Mile`:"Unknown"}

                      </Box>
                  </Box> }
                  <Box style={{marginBottom:"15px"}}>
                      <Box style={{fontWeight:600,display:"flex",gap:"7px",alignItems:"center"}}>
                        <div><BlurCircularIcon style={{color:"red",fontSize:"20px"}}/> </div>
                        <div>Loaded Weight</div>
                      </Box>
                      <Box  style={{paddingLeft:"20px",fontFamily: 'Poppins, sans-serif', fontWeight: 500, fontSize:"14px",color: '#064459'}}>
                         {selectedMarker?.basicData?.availableWeight?`${selectedMarker?.basicData?.availableWeight} Kg`:`0 Kg`}

                      </Box>
                  </Box>
                

                  <Box style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    {(auth.info.profile.usertype === "shipper" || auth.info?.profile.brokerId)&& (
                      <Grid item xs={2} className={classes.tableCell}>
                        {estimateRequested.loading &&
                        estimateRequested?.id === selectedMarker?.basicData?.id ? (
                          <CircularProgress size={25} />
                        ) : (
                          <Button
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: 100,
                              height: 50,
                              color: "white",
                              backgroundColor: "#01aac1",
                              borderRadius: 5,
                              fontSize: 10,
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                            }}
                            onClick={() => props.accessdFrom==='TruckBoard'?handleOriginAndDestinationModal(selectedMarker):handleGetEstimate(selectedMarker)}

                          >
                            <Typography variant="subtitle2">Book</Typography>
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Box>


                
              </Box>
          }

        </Box>

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>

      <Dialog
        open={phoneAlert.open}
        onClose={handlePhoneAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("phone_no_update")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhoneAlertClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handlePhoneAlertGo} color="primary">
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
        style={{
          // paddingTop: "220px",

        }}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" , overflow: "auto",
          height: "70vh",
          top: 10,}}
        >
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: 15, color: "#000" }}
          >
            {t("load_info_and_offer")}
          </Typography>

          {estimatedata.estimate && estimatedata.estimate.post_request ? (
            <Grid item xs={12}>
              <p style={{ marginTop: 10, color: "#000" }}>
                {t("selected_pickup")}:{" "}
                {props.pickupAddress && props.pickupAddress.description
                  ? props.pickupAddress.description
                  : ""}{" "}
                <br></br>
                {t("selected_drop")}:{" "}
                {props.dropAddress && props.dropAddress.description
                  ? props.dropAddress.description
                  : ""}{" "}
                <br></br>
                {t("selected_route_distance")}:{" "}
                {estimatedata.estimate && estimatedata.estimate.estimateDistance
                  ? estimatedata.estimate.estimateDistance
                  : ""}
                <br></br>
              </p>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type={"date"}
              margin="normal"
              fullWidth
              id="dropDate"
              label={"Drop Date"}
              name="dropDate"
              autoComplete="dropDate"
              onChange={handleChange}
              value={instructionData.dropDate}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />

          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadWeight"
              label={"Load Weight"}
              name="loadWeight"
              autoComplete="loadWeight"
              type="number"
              onChange={handleChange}
              value={instructionData.loadWeight}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                      <span>Kg</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>


          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadDimensions"
              label={"Load Dimensions"}
              name="loadDimensions"
              autoComplete="loadDimensions"
              onChange={handleChange}
              value={instructionData.loadDimensions}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryPerson"
              label={t("deliveryPerson")}
              name="deliveryPerson"
              autoComplete="deliveryPerson"
              onChange={handleChange}
              value={instructionData.deliveryPerson}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              focused
              id="deliveryPersonPhone"
              label={t("deliveryPersonPhone")}
              name="deliveryPersonPhone"
              placeholder="123456789"
              type="number"
              autoComplete="deliveryPersonPhone"
              onChange={handleChange}
              value={deliveryPersonPhoneWithOutPlus}
              className={
                isRTL === "rtl" ? [classes.inputRtl, classes.rightRty] : null
              }
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pickUpInstructions"
              label={t("pickUpInstructions")}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryInstructions"
              label={"Delivery Instructions"}
              name="deliveryInstructions"
              autoComplete="deliveryInstructions"
              onChange={handleChange}
              value={instructionData.deliveryInstructions}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="commodity"
              label={"Commodity"}
              name="commodity"
              autoComplete="commodity"
              onChange={handleChange}
              value={instructionData.commodity}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadIdentification"
              label={"Load Identification"}
              name="loadIdentification"
              autoComplete="loadIdentification"
              onChange={handleChange}
              value={instructionData.loadIdentification}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
              <TextField
                variant="outlined"
                type={"number"}
                margin="normal"
                fullWidth
                id="shipperOffer"
                label={"Load Rate"}
                name="shipperOffer"
                autoComplete="shipperOffer"
                onChange={handleChange}
                value={instructionData.shipperOffer}
                className={isRTL === "rtl" ? classes.inputRtl : null}
                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                        <span>$ / {estimatedata?.estimate?.post_request?.ratePerMile}$</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>



          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {!bookProgress ? (
              <>
                <Button
                  onClick={handleEstimateModalClose}
                  variant="contained"
                  color="primary"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={confirmBooking}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 10 }}
                >
                  {t("book_route")}
                </Button>
              </>
            ) : (
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <CircularProgress size={25} />
              </div>
            )}
          </Grid>
        </Grid>
      </Modal>
   </>
  );
});

export default Map;
