import React,{ useState, useEffect, useContext , useRef } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {
  Grid,
  Typography,
  Modal,
  Button,
  Link,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { FirebaseContext } from 'common';
import UsersCombo from 'components/UsersCombo';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import AlertDialog from '../components/AlertDialog';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, FormControl, FormLabel, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",alignItems:"center",
    lineBreak:"anywhere",
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width:"60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '@media (max-width: 800px)':{ width:"90%"} ,
    maxHeight:'70vh',
    overflow:"auto",
  },
  modalContainer:{
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width:"60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '@media (max-width: 800px)':{ width:"90%"} ,
    maxHeight:'70vh',
    overflow:"auto",
  },

  eachDispatcher: {
    "&:hover": {
      backgroundColor: 'rgba(180, 180, 180, .2)'
    }
  },
  showAllButton: {
    "&:hover": {
      // backgroundColor: '#01aac1', 
      color: '#fff'
    }
  },
  eachSettingContaiiner:{
    width:"50%"
  }
}));

const BookingHistory = () => {
  const { api } = useContext(FirebaseContext);
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const {
    cancelBooking,
    AssignBooking,
    updateBooking,
    deleteBookingFromSingle,
    fetchBookings,
    fetchProfile,
    changeAssigndDispatcher,
    TrackingNotificationForBroker
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const rootRef = useRef(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const classes = useStyles();
  const [dispatchers,setDispatchers]=useState([])
  
  const [selectdDispatcher,setSelectedDispatcher]=useState(null)
  const [selectdBooking,setSelectdBooking]=useState(null)
  const [dispatcherModal,handleChangeDispatcherModal]=useState(false)
  const [ActiveCompany,SetActiveCompany]=useState(null)
  const [SharedDispatcher,setSharedDispatcher]=useState([])
  
  const [shippersBasicData, setShippersBasicData]=useState(null)
  //this value

  const [ActiveCompanyType,setActiveCompanyType] = useState('carrier')
  const handleChange = (event) => {
      setActiveCompanyType(event.target.value);
      SetActiveCompany(null)
    };

  useEffect(()=>{
     if(auth.info.profile.SharedDispatcher&&auth.info.profile.SharedDispatcher.length>0){
       let allDispatchers=[]
      
        for(const dispatch of auth.info.profile.SharedDispatcher){
             if(auth.info.profile.usertype === 'shipper'){
                  if(dispatch.approved&&dispatch.aprovedByBroker){
                    let targetDispatch=userdata.users.filter(user => user.id===dispatch.id)
                    if(targetDispatch.length>0)  allDispatchers.push(targetDispatch[0])
                }
             }
             else if(auth.info.profile.usertype === 'fleetadmin'){
               if(dispatch.approved&&dispatch.aprovedByCarrier){
                  let targetDispatch=userdata.users.filter(user => user.id===dispatch.id)
                   if(targetDispatch.length>0)  allDispatchers.push(targetDispatch[0])
               }

             }
        }
        setSharedDispatcher(allDispatchers)
     }
  },[auth])

  useEffect(()=>{
    if(userdata.users&&(role==="fleetadmin"||role==='shipper'||isSuperDispatcher())){
      const owner={...auth.info.profile,id:auth.info.uid}
      const dispatchers=userdata.users.filter(user => user.usertype ==='dispatcher'&&(user?.carrierId===auth.info.uid || user?.brokerId===auth.info.uid 
                                                || user?.dispatcherId===auth.info.uid)
                                              &&user.approved&&user.emailVerified)
      let availebleUsers=[owner]
      if(dispatchers.length>0){
        availebleUsers.push(...dispatchers)
      }

      if(SharedDispatcher.length>0){
        availebleUsers.push(...SharedDispatcher)
      }
      setDispatchers(availebleUsers);
    }else{
      setDispatchers([]);
    }
  },[userdata,selectdBooking,SharedDispatcher]);

  const [columns,setColumns] = useState ([]);
  
 
 function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
 }

  const handleDispatcherManagementModal=(rowData)=>{
      setSelectdBooking(rowData)
      if(dispatchers.length>0&&rowData.assigndTo){
        let filteredUsers=dispatchers.filter(e=>{
          return e.id!==rowData.assigndTo
        })
         setDispatchers(filteredUsers)
      }
      handleChangeDispatcherModal(true)
      handleCloseNewTable()
  }


  const handleCloseDispatcherManagement=()=>{
    handleChangeDispatcherModal(false)
    setSelectdBooking(null)
    setSelectedDispatcher(null)
  }
  
  const[loadingWhileAssigning,setLoadnigWhileAssigning]=useState(false)
  const[errorWhileAssigning,setErrorWhileAssigning]=useState(false)

  const changeDispatcher=(newDispatcher)=>{
    let newData={
      booking:selectdBooking,
      newAssignedUser:newDispatcher,
      adminId:auth.info.uid,
      from:auth.info?.profile?.usertype==='shipper'?'broker':'carrier'
    }
    if(isSuperDispatcher()) newData['from']=selectdBooking.assigndFromBroker?"broker":"carrier"

    if(selectdBooking){
      setLoadnigWhileAssigning(true)
      dispatch(changeAssigndDispatcher(newData))
      .then(response => {
        setLoadnigWhileAssigning(false)
        if(response?.error){
          setErrorWhileAssigning(response?.error)
          setTimeout(()=>{
            handleChangeDispatcherModal(false)
            setSelectdBooking(null)
            setSelectedDispatcher(null)
            setErrorWhileAssigning(false)
          },1000)
        }
       else if(response?.msg){
          handleChangeDispatcherModal(false)
          setSelectdBooking(null)
          setSelectedDispatcher(null)
          setErrorWhileAssigning(false)
        }
        else{
          handleChangeDispatcherModal(false)
          setSelectdBooking(null)
          setSelectedDispatcher(null)
          setErrorWhileAssigning(false)
        }
       
        
      })
      .catch(error => {
        // console.error('Dispatch error:', error);
        setLoadnigWhileAssigning(false)
        setErrorWhileAssigning("Error occured please try again")
      
        setTimeout(()=>{
          handleChangeDispatcherModal(false)
          setSelectdBooking(null)
          setSelectedDispatcher(null)
          setErrorWhileAssigning(false)
        },1000)
      });
    }
    dispatch(fetchBookings())
  } 

const isBookMultiple=(loadId)=>{
    if(!loadId||!shippersBasicData||shippersBasicData.length===0) return false
    const allBookings=shippersBasicData.filter((e=>{
      return e.loadId===loadId
    }))
    return allBookings.length>0
}

const[allBookingsWithSameLoadId,setAllBookingsWithSameLoadId]=useState([])
const [openNewTable,setOpenNewTable]=useState(false)

function actualAentId(){

  if(isSuperDispatcher() || auth.info.profile?.dispatcherId ) return ActiveCompany?.value||null
  if(auth.info.profile.usertype === 'dispatcher' && auth.info?.profile?.carrierId) return auth.info.profile.carrierId
  if(auth.info.profile.usertype === 'dispatcher' && auth.info?.profile?.brokerId) return auth.info?.profile?.brokerId
  if(auth.info.profile.usertype === 'driver') return auth.info?.profile?.fleetadmin
  
  return auth.info.uid
  
}

const bookExistForUser=(book)=>{
    
   if(!book.deletedFrom||book.deletedFrom.length===0) return true
   let isBookDeleted=book.deletedFrom.filter(e=>{
      return e===actualAentId()
   }).length===0

   return isBookDeleted

}

const handleAllBookingWithSameLoadId=(loadId)=>{
  if(!loadId||!shippersBasicData||shippersBasicData.length===0){ 
       setAllBookingsWithSameLoadId([])
    }
    else{
        const allBookings=shippersBasicData.filter((e=>{
          return e.loadId===loadId && bookExistForUser(e)
        }))
        setAllBookingsWithSameLoadId(allBookings)
    }
    setOpenNewTable(true)
}

const getUnreadMessagesOfUser=(Booking)=>{
  const unreadMessages = Booking?.unreadMessages

  if(!unreadMessages || unreadMessages.length==0) return 0
  if(role==='shipper'||role==='fleetadmin'){
    const currentUserId = auth.info.uid

    return unreadMessages.filter(e=>e.to===currentUserId).length
  }
  else if(Booking.assigndTo === auth.info.uid){
    const currentUserId = Booking?.carrierId

    return unreadMessages.filter(e=>e.to===currentUserId).length
  }
  
  return 0

}

const [isSureToDelete,HandleIsSureToDelete]=useState({open:false,book:null})
function deleteBooking(){
   if(isSureToDelete.book){

    dispatch(deleteBookingFromSingle({Book:isSureToDelete.book , userId:auth.info.uid}))
     setTimeout(()=>{
      HandleIsSureToDelete({open:false,book:null})
      handleCloseNewTable()
     },1000)
   }
}

const canelIsNotAllowed=(agent,assigndTo,assigndFromBroker)=>{

   return role!==agent&&assigndTo!==auth.info?.uid&&assigndFromBroker!==auth.info?.uid

}

const isThirdPartyDispatchCanChangeAgent=(assignedId)=>{
      if(!assignedId) return false
      const isHeAssigned = auth.info?.uid === assignedId
      const isHisAgentAssigned = giveIdThenIwillGiveYouData(assignedId)?.dispatcherId === auth.info?.uid

      return isSuperDispatcher() && (isHeAssigned || isHisAgentAssigned)
}

const [dataOnTable,setDataOnTable]=useState([])


const paymentBackground=(status)=>{
    if(!status || status === 'unpaid') return 'red'
    if(status === 'processing') return '#fc7b03'
    if(status === 'verified') return 'green'
    return 'red'
}

const pageBackgroundColor=()=>{
  if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
  if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
  return "#01aac1"
}


const isSpecialAdmin=()=>{
     
  return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
}

 useEffect(()=>{
     if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.specialAdmin || auth?.info?.profile?.specialAdmin || auth?.info?.profile?.usertype === 'customer'){
      setColumns([
        { title: t('actions'), field: 'actions',cellStyle: {padding: isRTL=== 'rtl' ? '0px 100px 0px 0px':'0px 0px 0px 50px', width: 300,minWidth:300}, headerStyle:{
          backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300, padding: isRTL === 'rtl' ? '0px 80px 0px 0px' : '0px 0px 0px 60px',textAlign:"center"}, 
          render: rowData => 
              <>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:5,marginTop:5}}>
                  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexWrap:"wrap",gap:"10px"}}>
                    <Button 
                      onClick= {() => {
                        if(canelIsNotAllowed('fleetadmin',rowData.assigndTo,rowData.assigndFromBroker)){
                              
                        }
                        else if(settings.AllowCriticalEditsAdmin && (role==='shipper' || role ==='admin'|| role ==='dispatcher'|| role ==='fleetadmin')){
                          if(rowData?.status==='NEW' || rowData?.status==='ACCEPTED'){
                            setSelectedBooking(rowData);
                            setOpenConfirm(true);
                          }else{
                            setTimeout(()=>{
                              dispatch(cancelBooking({
                                reason: t('cancelled_incomplete_booking'),
                                booking:rowData
                              }));
                              handleCloseNewTable()
                            },1500);
                          }
                        }else{
                          alert(t('demo_mode'));
                        }
                        }}
                      disabled={canelIsNotAllowed('fleetadmin',rowData.assigndTo,rowData.assigndFromBroker)||rowData.status==='NEW' || rowData.status==='ACCEPTED'? false:true}
                              
                      style={{textTransform:"inherit",color:"white",background:t(rowData.status)==='NEW'|| t(rowData.status)==='ACCEPTED'?"red":"rgba(171, 156, 155, 0.45)"}}>
                        Cancel
                      </Button> 

                      {((role==='dispatcher' || role ==='admin'||role==='fleetadmin')&&(rowData?.approved===false&&rowData.status==="NEW"))&&
                        <div>
                          <Button 
                              onClick= {() => {
                                    handleAsiignBooking(rowData)
                                }}
                              style={{textTransform:"inherit",color:"white",background:"#4fc46c",}
                              }>
                                Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position:'absolute', left: '65px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background:pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span> : ''}
                          </Button> 
                        </div>
                        }

                        {((rowData.status == 'NEW'&&rowData.approved)||rowData.status == "ACCEPTED" || rowData.status == 'STARTED'|| rowData.status == 'ARRIVED'|| rowData.status == 'REACHED') &&
                            <div >
                                <Button onClick= {() => {
                                    handleAsiignBooking(rowData)
                                }}
                                   style={{backgroundColor: pageBackgroundColor(),color:"white"}}>Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position: 'absolute', left: '50px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background: pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span>: ''}</Button> 
                            </div>
                        }

                        {rowData.status==='CANCELLED'&&(role==='shipper'||role==='fleetadmin') && <div><Button onClick={()=>HandleIsSureToDelete({open:true,book:rowData})} style={{color:"red"}}>Delete</Button></div>}


                  </div>
                  
                  
                  
                </div>
              </>

         },
      
        { title: "Carrier Assigned Agent", field: 'assigndTo', 
           render: rowData => <div>
                   <div>
                     { rowData?.assigndTo?
                        <>
                          {giveIdThenIwillGiveYouData(rowData.assigndTo).approved?
                              <Link href={`user/${rowData.assigndTo}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                  {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                              </Link>
                              :
                              <div style={{color: 'red'}}>
                                  {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                              </div>
                          
                           }
                          {giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId&&
                                  <>
                                     <span style={{color:"black",padding:"0 5px 0 5px",fontWeight:"700",fontSize:"16px"}}>/</span>
                                      <Link href={`user/${giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                            {giveMeIdThenIwillGiveYouName(giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId)}
                                        </Link>
                                  </>
                          }
                        </>
                        :"Not Added yet"}
                    </div>

                     {(rowData.status==="NEW"||rowData.status === "ACCEPTED" || rowData.status === 'STARTED')&&(role==="fleetadmin"||isThirdPartyDispatchCanChangeAgent(rowData.assigndTo))&&
                     <div>
                       <Button style={{color:pageBackgroundColor()}} onClick={()=>handleDispatcherManagementModal(rowData)}>Change</Button>
                     </div>
                     }
               </div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',  width: 170,minWidth:170}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}
        },
    
        { title: t('booking_ref'), field: 'reference', 
        render: rowData => 
          <div style={{color: (rowData.manual || !rowData.customer)?"red":""}}>
              {rowData.reference} {(rowData.manual || !rowData.customer) ?' Manually ':""}</div>,
        cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:400,maxWidth:400},
        headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
       
        { title: 'Shop Name',field: 'shop',
        render: rowData => 
             <div style={{textAlign:"center"}}>
              <Link href={`user/${rowData.customer}?redirected=bookings`} style={{textDecoration: 'none'}}>
                  <div style={{}}>{giveIdThenIwillGiveYouData(rowData?.customer)?.shopeName}</div>
              </Link>
              {rowData?.role&&<div >{rowData?.role==='shipper'?"Shipper":"Receiver"}</div>}
             </div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150,minWidth:150},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15}  
        },

        { title: "Driver occupied", field: 'driver_name',
          render: rowData => 
            rowData?.driverDetails?.id?
            <span>{giveIdThenIwillGiveYouData(rowData?.driverDetails?.id)?.firstName} {giveIdThenIwillGiveYouData(rowData?.driverDetails?.id)?.lastName}</span>
            :"Not Set"
            ,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width: 170,minWidth:170},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} 
        }, 

        { title: 'Route',field: 'route',
        render: rowData => 
             <>
             <div >{rowData?.route?.identification}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },

        { title: 'Receiver Name',field: 'merchantName',
        render: rowData => 
             <>
             <div >{(!rowData?.role||rowData?.role === "shipper" || rowData?.manual)? rowData.merchantName:""}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },

        { title: 'Receiver Mobile',field: 'merchantPhone',
        render: rowData => 
             <>
             <div >{(!rowData?.role||rowData?.role === "shipper" || rowData?.manual)? rowData.merchantPhone:""}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },
        { title: 'Shipper Name',field: 'shipperName',
        render: rowData => 
             <>
             <div >{rowData?.role === "receiver"? rowData.merchantName: rowData?.shipperName}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },

        { title: 'Shipper Mobile',field: 'shipprPhone',
        render: rowData => 
             <>
               <div >{rowData?.role === "receiver"? rowData.merchantPhone: rowData?.shipprPhone}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },

        { title: 'Description',field: 'description',
        render: rowData => 
             <>
             <div >{rowData.description}</div>
             </>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',minWidth:200,width:200},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
        },

        
        { title: t('pickup_address'), field: 'pickupAddress', 
         cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width: 300,minWidth: 300}, 
         headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 200,minWidth: 200,} },
        
        { title: 'Pickup Date', field: 'pickupDate', 
          render: rowData => rowData.pickupDate?moment(rowData.pickupDate).format('lll'):'Not Set',
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 170,minWidth:170}, 
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},

        { title: t('drop_address'), field: 'dropAddress', 
         cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width: 200,minWidth:200}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
        
        { title: 'Drop Date', field: 'dropDate', 
           render: rowData => rowData.dropDate?moment(rowData.dropDate).format('lll'):'Not Set',
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width: 170,minWidth:170}, 
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},

        { title: t('booking_date'), field: 'bookingDate', 
           render: rowData => rowData.bookingDate?moment(rowData.bookingDate).format('lll'):null,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 170,minWidth:170}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
        
        
         { title: 'Allowed Payment',field: 'allowedPayment',
        render: rowData => 
           <>
             <div style={{fontWeight:"600"}}>{rowData.allowedPayment === 'deposit'?'Deposit':rowData.allowedPayment === 'full' ? "Full Payment" : "Not Set"}</div>
           </>,
        cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
        headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  
         },
       
        { title: 'Deposit Payment',field: 'depositPayment',
          render: rowData => 
             <>
               <div  style={{fontWeight:"600", background:paymentBackground(rowData.depositPayment?.status),color:"white",textAlign:"center",borderRadius:10}}>
                    {!rowData.depositPayment?.status&& 'Not Set'}
                   {(rowData.depositPayment?.status ==='unpaid')&& 'Unpaid'}
                   {(rowData.depositPayment?.status ==='processing')&& 'Processing'}
                   {(rowData.depositPayment?.status ==='verified')&& 'Verified'}
                   {(rowData.depositPayment?.status ==='failed')&& 'Failed'}
               </div>
               {rowData.depositPayment?.tx && <div style={{fontWeight:"500",fontSize:14,marginTop:3}}>{rowData.depositPayment?.tx}</div>}
             </>,
          cellStyle:{width: 170,minWidth:170,textAlign:"center"},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"}  
        },

        { title: 'Full Payment',field: 'fullPayment',
        render: rowData => 
             <>
               <div  style={{fontWeight:"600",background:paymentBackground(rowData.fullPayment?.status),color:"white",textAlign:"center",borderRadius:10}}>
                   {!rowData.fullPayment?.status&& 'Not Set'}
                   {(rowData.fullPayment?.status ==='unpaid')&& 'Unpaid'}
                   {(rowData.fullPayment?.status ==='processing')&& 'Processing'}
                   {(rowData.fullPayment?.status ==='verified')&& 'Verified'}
                   {(rowData.fullPayment?.status ==='failed')&& 'Failed'}
               </div>
               {rowData.fullPayment?.tx &&<div  style={{fontWeight:"500",fontSize:14,marginTop:3}}>{rowData.fullPayment?.tx}</div>}
             </>,
          cellStyle:{textAlign:"center",width: 170,minWidth:170},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"}  
        },

        { title: 'General Payment Status', field: 'paymentStatus', 
         render: rowData => 
            <>
              <div  style={{fontWeight:"600"}}>
                 {rowData?.paymentStatus ==='paid'? 
                   <div style={{display:"flex",justifyContent:'center',alignItems:"center",gap:5}}>
                       <div><CheckCircleIcon style={{color:"green"}}/>  </div>
                        <div>{rowData?.paymentAmount?`${rowData?.paymentAmount} Birr`:"Not Set"}</div>
                   </div>
                 :
                  <div style={{display:"flex",justifyContent:'center',alignItems:"center",gap:5}}>
                      <div><RadioButtonUncheckedIcon style={{color:"red"}}/>  </div>
                      <div>{rowData?.paymentAmount?`${rowData?.paymentAmount} Birr`:"Not Set"}</div>
                 </div>
               
                  }
                 
              </div>
            </>,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width: 170,minWidth:170,textAlign:"center"},
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"} 
        },

        { title: 'Load Image', field:'loadImage', 
        render: (rowData) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
                alignItems: "center",
              }}
            >
                {rowData.loadImage?
                  <img src={rowData?.loadImage} alt='Load image' width={100} style={{width: 150}}/>
                :"Not Set"}
            </div>
          );
        },
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',textAlign:"center"},
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"} },
        
        { title: 'Load Weight', field:'loadWeight', 
        render: (rowData) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
                alignItems: "center",
              }}
            >
                {rowData.loadWeight?`${rowData.loadWeight} Kg`:"Unknown"}
            </div>
          );
        },
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',textAlign:"center"},
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"} },
        
        { title: 'Licence Image', field:'licenceImage', 
           render: (rowData) => {
             return (
               <div
                 style={{
                   display: "flex",
                   justifyContent: "flex-start",
                   gap: "10px",
                   alignItems: "center",
                   alignItems: "center",
                 }}
               >
                   {rowData.licenceImage?
                     <img src={rowData?.licenceImage} alt='Licence image' width={100} style={{width: 150}}/>
                   :"Not Set"}
               </div>
             );
           },
              cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',textAlign:"center"},
              headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"} },

        // { title: 'Load Identification', field: 'loadIdentification', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },

        // { title: t('take_pickup_image'),  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 30}},
        // { title: t('take_deliver_image'),  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}},
        
        { title: t('trip_start_time'), field: 'trip_start_time',
             cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 170,minWidth:170}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
        { title: t('trip_end_time'), field: 'trip_end_time',
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 170,minWidth:170}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
        
        { title: 'Cancelation Reason', field: 'reason',
          render:rowData=> <div style={{fontSize:"12px"}}>{rowData.status==='CANCELLED'&&rowData?.reason}</div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:200,minWidth:200},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} 
        }

        ])
     }
     else if(role==="fleetadmin"||(role==="dispatcher" && !auth.info?.profile?.brokerId)||role==="admin"){
        setColumns([
          { title: t('actions'), field: 'actions',cellStyle: {padding: isRTL=== 'rtl' ? '0px 100px 0px 0px':'0px 0px 0px 50px', width: 300,minWidth:300}, headerStyle:{
            backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300, padding: isRTL === 'rtl' ? '0px 80px 0px 0px' : '0px 0px 0px 60px',textAlign:"center"}, 
            render: rowData => 
                <>
                  <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexWrap:"wrap",gap:"10px"}}>
                     <Button 
                      onClick= {() => {
                        if(canelIsNotAllowed('fleetadmin',rowData.assigndTo,rowData.assigndFromBroker)){
                              
                        }
                        else if(settings.AllowCriticalEditsAdmin && (role==='shipper' || role ==='admin'|| role ==='dispatcher'|| role ==='fleetadmin')){
                          if(rowData?.status==='NEW' || rowData?.status==='ACCEPTED'){
                            setSelectedBooking(rowData);
                            setOpenConfirm(true);
                          }else{
                            setTimeout(()=>{
                              dispatch(cancelBooking({
                                reason: t('cancelled_incomplete_booking'),
                                booking:rowData
                              }));
                              handleCloseNewTable()
                            },1500);
                          }
                        }else{
                          alert(t('demo_mode'));
                        }
                        }}
                      disabled={canelIsNotAllowed('fleetadmin',rowData.assigndTo,rowData.assigndFromBroker)||rowData.status==='NEW' || rowData.status==='ACCEPTED'? false:true}
                              
                      style={{textTransform:"inherit",color:"white",background:t(rowData.status)==='NEW'|| t(rowData.status)==='ACCEPTED'?"red":"rgba(171, 156, 155, 0.45)"}}>
                        Cancel
                      </Button> 

                      {((role==='dispatcher' || role ==='admin'||role==='fleetadmin')&&(rowData?.approved===false&&rowData.status==="NEW"))&&
                        <div>
                          <Button 
                              onClick= {() => {
                                    handleAsiignBooking(rowData)
                                }}
                              style={{textTransform:"inherit",color:"white",background:"#4fc46c",}
                              }>
                                Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position:'absolute', left: '65px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background:pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span> : ''}
                          </Button> 
                        </div>
                        }

                        {((rowData.status == 'NEW'&&rowData.approved)||rowData.status == "ACCEPTED" || rowData.status == 'STARTED'|| rowData.status == 'ARRIVED'|| rowData.status == 'REACHED') &&
                            <div >
                              <Link href={`active-booking/${rowData.id}`}>
                                  <Button style={{backgroundColor: pageBackgroundColor(),color:"white"}}>Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position: 'absolute', left: '50px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background: pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span>: ''}</Button> 
                              </Link>
                            </div>
                        }

                        {rowData.status==='CANCELLED'&&(role==='shipper'||role==='fleetadmin') && <div><Button onClick={()=>HandleIsSureToDelete({open:true,book:rowData})} style={{color:"red"}}>Delete</Button></div>}


                  </div> 
                </>

           },
        
          { title: "Carrier Assigned Agent", field: 'assigndTo', 
             render: rowData => <div>
                     <div>
                       { rowData?.assigndTo?
                          <>
                            {giveIdThenIwillGiveYouData(rowData.assigndTo).approved?
                                <Link href={`user/${rowData.assigndTo}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                    {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                                </Link>
                                :
                                <div style={{color: 'red'}}>
                                    {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                                </div>
                            
                             }
                            {giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId&&
                                    <>
                                       <span style={{color:"black",padding:"0 5px 0 5px",fontWeight:"700",fontSize:"16px"}}>/</span>
                                        <Link href={`user/${giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                              {giveMeIdThenIwillGiveYouName(giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId)}
                                          </Link>
                                    </>
                            }
                          </>
                          :"Not Added yet"}
                      </div>

                       {(rowData.status==="NEW"||rowData.status === "ACCEPTED" || rowData.status === 'STARTED')&&(role==="fleetadmin"||isThirdPartyDispatchCanChangeAgent(rowData.assigndTo))&&
                       <div>
                         <Button style={{color:pageBackgroundColor()}} onClick={()=>handleDispatcherManagementModal(rowData)}>Change</Button>
                       </div>
                       }
                 </div>,
            cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}
          },
          
          { title: "Broker Assigned Agent", field: 'assigndFromBroker', 
           render: rowData => 
                 <div  style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                 {
                   isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length  === 0 ? 
                   <div style={{ paddingLeft: 20}}> </div> :
                   <div> 
                      {rowData?.assigndFromBroker? 
                         <>
                            {giveIdThenIwillGiveYouData(rowData.assigndFromBroker)?.approved?
                                <Link href={`user/${rowData.assigndFromBroker}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                  {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.firstName+" "+giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.lastName }
                                </Link>
                                :
                                <div style={{color:"red"}}>
                                    {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.firstName+" "+giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.lastName }
                                </div>


                            }

                            {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId&&
                                    <>
                                        <span style={{color:"black",padding:"0 5px 0 5px",fontWeight:"700",fontSize:"16px"}}>/</span>
                                         <Link href={`user/${giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                              {giveMeIdThenIwillGiveYouName(giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId)}
                                          </Link>
                                    </>
                            }
                         </>
                         :"Not Added yet"
                      }
                        
                        {(rowData.status==="NEW"||rowData.status === "ACCEPTED" || rowData.status === 'STARTED')&&isThirdPartyDispatchCanChangeAgent(rowData.assigndFromBroker) &&
                            <div>
                              <Button style={{color:pageBackgroundColor()}} onClick={()=>handleDispatcherManagementModal(rowData)}>Change</Button>
                            </div>
                            }
                   </div>
                 }
                 </div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350, height: 150, padding: openNewTable ? '0 5px' : '0'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}
           },

          { title: t('booking_ref'), field: 'reference', 
          render: rowData => <div style={{color:rowData.manualBooking?"red":(rowData.loadId?"blue":"")}}>{rowData.reference} {rowData.manualBooking?'( Manually )':(rowData.loadId?'(Private Load)':'')}</div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:400,maxWidth:400},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
         
          { title: t('customer_name'),field: 'customer_name',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
          
          { title: "Driver occupied", field: 'driver_name',
          render: rowData => 
            <span>{rowData?.post_request?.driver?.firstName?
                  `${rowData?.post_request?.driver?.firstName} ${rowData?.post_request.driver?.lastName}` : 
                  `${rowData?.driverDetails?.firstName} ${rowData?.driverDetails?.lastName}`||""}</span>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} 
        }, 

          { title: t('pickup_address'), field: 'pickupAddress', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('drop_address'), field: 'dropAddress', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
        
          { title: t('booking_date'), field: 'bookingDate', render: rowData => rowData.bookingDate?moment(rowData.bookingDate).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
         
          { title: 'Pickup Date', field: 'pickupDate', render: rowData => rowData.pickupDate?moment(rowData.pickupDate).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
          { title: 'Drop Date', field: 'dropDate', render: rowData => rowData.dropDate?moment(rowData.dropDate).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
          
          { title: t('trailer_type'), field: 'trailerType', render: rowData => rowData.trailerType? t(rowData.trailerType): null, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}  },

          { title: 'Broker Company Name', field: 'brokerName', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: 'Assigned Person Name', field: 'assignedPersonName', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: 'Assigned Person Phone', field: 'assignedPersonPhone', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          
          { title: 'Commodity', field: 'commodity', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: 'Load Identification', field: 'loadIdentification', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
         
          { title: 'Load Weight', field:'loadWeight', 
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  alignItems: "center",
                  alignItems: "center",
                }}
              >
                  {rowData.loadWeight?`${rowData.loadWeight} Kg`:"Unknown"}
              </div>
            );
          },
             cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          
          { title: 'Load Dimensions', field:'loadDimensions', 
             cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },


          { title: t('tripInstructions'), field: 'tripInstructions', hidden: true, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('deliveryPerson'), field: 'deliveryPerson', hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}  },
          { title: t('deliveryPersonPhone'), field: 'deliveryPersonPhone', hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('pickUpInstructions'), field: 'pickUpInstructions', hidden: false ,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('deliveryInstructions'), field: 'deliveryInstructions', hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}  },
          { title: t('load_rate'), field: 'shipperOffer', hidden: false, render: rowData => rowData.shipperOffer? `${settings.symbol}${rowData.shipperOffer}`: null, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}  },
          { title: t('truck_load'), 
          render: rowData => <span>{rowData.optionSelected ? rowData.optionSelected?.name :""}</span> , hidden: false,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('take_pickup_image'),  field: 'pickup_image',render: rowData => rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 30}},
          { title: t('take_deliver_image'),  field: 'deliver_image',render: rowData => rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}},
          { title: t('trip_start_time'), field: 'trip_start_time',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 300}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('trip_end_time'), field: 'trip_end_time',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 300}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('total_time'), field: 'total_trip_time',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('distance'), field: 'distance',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: t('vehicle_no'), field: 'vehicle_number',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,} },
          { title: 'Cancelation Reason', field: 'reason',
          render:rowData=> <div style={{fontSize:"12px"}}>{rowData.status==='CANCELLED'&&rowData?.reason}</div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:200,minWidth:200},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} }
        ])
      }else{
        let newColumn=[
          { title: t('actions'), field: 'actions',
           cellStyle: {padding: isRTL=== 'rtl' ? '0px 100px 0px 0px':'0px 0px 0px 50px',width: 300,minWidth:300, height: 150},
           headerStyle:{backgroundColor: pageBackgroundColor(),textAlign:"center",fontWeight: "bold",color: "white",fontSize: 15,width: 300, padding: isRTL === 'rtl' ? '0px 80px 0px 0px' : '0px 0px 0px 60px'},
          render: rowData => 
                  <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?
                    <Button onClick={()=>handleAllBookingWithSameLoadId(rowData?.loadId)} style={{margin: '0px 15px', border: '1px solid #01aac1'}} className={classes.showAllButton}>Private Board Bids</Button>
                    :<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexWrap:"wrap",gap:"10px"}}>
                       
                       {
                       <Button 
                          onClick= {() => {
                            if(canelIsNotAllowed('shipper',rowData.assigndTo,rowData.assigndFromBroker)){
                              
                            }
                            else if(settings.AllowCriticalEditsAdmin && (role==='shipper' || role ==='admin'|| role ==='dispatcher'|| role ==='fleetadmin')){
                              if(rowData?.status==='NEW' || rowData?.status==='ACCEPTED'){
                                setSelectedBooking(rowData);
                                setOpenConfirm(true);
                              }else{
                                setTimeout(()=>{
                                  dispatch(cancelBooking({
                                    reason: t('cancelled_incomplete_booking'),
                                    booking:rowData
                                  }));
                                  handleCloseNewTable()
                                },1500);
                              }
                            }else{
                              alert(t('demo_mode'));
                            }
                            }}
                          disabled={canelIsNotAllowed('shipper',rowData.assigndTo,rowData.assigndFromBroker)||rowData.status==='NEW' || rowData.status==='ACCEPTED'? false:true 
                                     } 
                          style={{textTransform:"inherit",color:"white",background:t(rowData.status)==='NEW'|| t(rowData.status)==='ACCEPTED'?"red":"rgba(171, 156, 155, 0.45)"}}>
                          Cancel
                        </Button> }
                      
                        {((rowData?.approved===false&&rowData.status==="NEW"))&&
                          <div>
                           <Button 
                              onClick= {() => {
                                handleAsiignBooking(rowData)
                            }}
                              style={{textTransform:"inherit",color:"white",background:"#4fc46c", }
                              }>
                                Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position:'absolute', left: '65px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background:pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span> : ''}
                          </Button> 
                          </div>
                        }
                        {(((rowData.status == 'NEW'&&rowData.approved)
                                                                  ||rowData.status == "ACCEPTED"
                                                                  ||rowData.status == 'STARTED'|| rowData.status == 'ARRIVED'
                                                                  || rowData.status == 'REACHED'))&&
                            <div >
                              <Link href={`active-booking/${rowData.id}`}>
                                  <Button style={{backgroundColor: pageBackgroundColor(),color:"white"}}>Manage Booking {getUnreadMessagesOfUser(rowData) ? <span style={{fontSize: 8, backgroundColor: "white", position: 'absolute', left: '50px', bottom: '24px', borderRadius: '50%', width: 23, height: 23, display: "flex", alignItems: 'center', justifyContent: 'center'}}><span style={{background: pageBackgroundColor(), borderRadius: '50%', width: '18px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff'}}>{getUnreadMessagesOfUser(rowData)}</span></span>: ''}</Button> 
                              </Link>
                            </div>
                        }
          
                       {rowData.status==='CANCELLED'&&(role==='shipper'||role==='fleetadmin') && <div><Button onClick={()=> HandleIsSureToDelete({open:true,book:rowData})} style={{color:"red"}}>Delete</Button></div>}

                    </div>}
                  
                  </div>
           },

           { title: "Carrier Assigned Agent", field: 'assigndTo', 
            render: rowData => 
                  <div  style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {
                    isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length  === 0 ? 
                    <div style={{ paddingLeft: 20}}> </div> :
                    <div> 
                       {rowData?.assigndTo? 
                            <>
                              {giveIdThenIwillGiveYouData(rowData.assigndTo).approved?
                                <Link href={`user/${rowData.assigndTo}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                    {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                                </Link>
                                :
                                <div style={{color: 'red'}}>
                                    {giveIdThenIwillGiveYouData(rowData.assigndTo)?.firstName+" "+ giveIdThenIwillGiveYouData(rowData.assigndTo)?.lastName}
                                </div>
                            
                             }
                              
                              {giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId&&
                                    <>
                                      <span style={{color:"black",padding:"0 5px 0 5px",fontWeight:"700",fontSize:"16px"}}>/</span>
                                        <Link href={`user/${giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                              {giveMeIdThenIwillGiveYouName(giveIdThenIwillGiveYouData(rowData?.assigndTo)?.dispatcherId)}
                                          </Link>
                                    </>
                                }
                            </>
                           
                           :"Not Added yet"}

                    </div>
                  }
                  </div>,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350, height: 150, padding: openNewTable ? '0 5px' : '0'}, 
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
       
           { title: "Broker Assigned Agent", field: 'assigndFromBroker', 
           render: rowData => 
                 <div  style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                 {
                   isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length  === 0 ? 
                   <div style={{ paddingLeft: 20}}> </div> :
                   <div> 
                      {rowData?.assigndFromBroker? 
                        <>
                          {giveIdThenIwillGiveYouData(rowData.assigndFromBroker).approved?
                                <Link href={`user/${rowData.assigndFromBroker}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                  {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.firstName+" "+giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.lastName }
                                </Link>
                                :
                                <div style={{color:"red"}}>
                                    {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.firstName+" "+giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.lastName }
                                </div>


                            }

                          {giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId&&
                            
                               <>
                                  <span style={{color:"black",padding:"0 5px 0 5px",fontWeight:"700",fontSize:"16px"}}>/</span>
                                       <Link href={`user/${giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId}?redirected=bookings`} style={{textDecoration: 'none'}}>
                                         {giveMeIdThenIwillGiveYouName(giveIdThenIwillGiveYouData(rowData?.assigndFromBroker)?.dispatcherId)}
                                      </Link>
                                </>
                            }
                        </>
                        :"Not Added yet"}
                      
                      {(rowData.status==="NEW"||rowData.status === "ACCEPTED" || rowData.status === 'STARTED')&&role==="shipper" &&
                       <div>
                         <Button style={{color:pageBackgroundColor()}} onClick={()=>handleDispatcherManagementModal(rowData)}>Change</Button>
                       </div>
                       }

                   </div>
                 }
                 </div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350, height: 150, padding: openNewTable ? '0 5px' : '0'}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}
           },
  

           { title: t('booking_ref'), field: 'reference', 
           render: rowData =>
               <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?
                    <div style={{ paddingLeft: 10}}>   </div>
                   :

                    <div style={{color:rowData.manualBooking&&"red"}}>{rowData.reference} {rowData.manualBooking&&'( Manually )'}</div>
                   }
               </div>,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:400,maxWidth:400, padding: openNewTable ? '0 5px' : '0', height: 150,},
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
     
           { title: "Driver occupied", field: 'driver_name',
           render: rowData => 
             <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
               {
                 isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?
                 <div>   </div>
                 :
                 <span>
                   {rowData?.post_request?.driver?.firstName?
                   `${rowData?.post_request?.driver?.firstName} ${rowData?.post_request?.driver?.lastName}` : 
                   `${rowData?.driverDetails?.firstName} ${rowData?.driverDetails?.lastName}`||""}
                 </span>
               }
             </div>,
           cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 100, height: 150, padding: openNewTable ? '0 5px' : '0'},
           headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} 
         },
              

          { title: t('pickup_address'), field: 'pickupAddress',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.pickupAddress}</div>, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('drop_address'), field: 'dropAddress',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.dropAddress}</div> , cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
        
          { title: t('booking_date'), field: 'bookingDate', render: rowData => 
            <div  style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {
              isBookMultiple(rowData?.loadId) && allBookingsWithSameLoadId.length === 0 ? 
              <div>   </div>
              :
              rowData.bookingDate?moment(rowData.bookingDate).format('lll'):null
            }
            </div>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}
            },

          { title: 'Pickup Date', field: 'pickupDate', render: rowData => rowData.pickupDate?moment(rowData.pickupDate).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
          { title: 'Drop Date', field: 'dropDate', render: rowData => rowData.dropDate?moment(rowData.dropDate).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350}, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,minWidth: 300,}},
           
            
          { title: t('trailer_type'), field: 'trailerType', 
           render: rowData => 
                    <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {!(isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0 )&&<>
                            {rowData.trailerType? t(rowData.trailerType): null}
                        </>}
                    </div>, 
            cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 350, height: 150, padding: openNewTable ? '0 5px' : '0'},
            headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
          
            
          { title: 'Commodity', field: 'commodity', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: 'Load Identification', field: 'loadIdentification', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: 'Load Weight and Dimensions', field:'loadDetails', cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          
          { title: t('tripInstructions'), field: 'tripInstructions', hidden: true, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('deliveryPerson'), field: 'deliveryPerson', render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.deliveryPerson}</div>, hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
          { title: t('deliveryPersonPhone'), field: 'deliveryPersonPhone',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.deliveryPersonPhone}</div>, hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('pickUpInstructions'), field: 'pickUpInstructions', render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.pickupInstructions}</div>, hidden: false ,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('deliveryInstructions'), field: 'deliveryInstructions', render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.deliveryInstructions}</div>, hidden: false,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
          { title: t('load_rate'), field: 'shipperOffer', hidden: false, render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.shipperOffer ? `${settings.symbol}${rowData.shipperOffer}` : null}</div>, cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150 , height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}  },
          { title: t('truck_load'), 
             render: rowData => <span style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.optionSelected ? rowData.optionSelected?.name :""}</span> , hidden: false,
             cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 400, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('take_pickup_image'),  field: 'pickup_image',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.pickup_image?<img alt='Pick Up' src={rowData.pickup_image} style={{width: 150}}/>:null}</div>, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}, cellStyle: {width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'}},
          { title: t('take_deliver_image'),  field: 'deliver_image',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.deliver_image?<img alt='Deliver' src={rowData.deliver_image} style={{width: 150}}/>:null}</div>, editable:'never', hidden: false, headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300}, cellStyle: {width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'}},
          { title: t('trip_start_time'), field: 'trip_start_time',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.trip_start_time}</div>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 300, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('trip_end_time'), field: 'trip_end_time',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.trip_end_time}</div> ,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 300, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('total_time'), field: 'total_trip_time',render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.total_time}</div>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('distance'), field: 'distance', render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{rowData.distance}</div>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: t('vehicle_no'), field: 'vehicle_number',
          render: rowData => <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {rowData.vehicle_number}</div>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left', width: 150, height: 150, padding: openNewTable ? '0 5px' : '0'},headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
          { title: 'Cancelation Reason', field: 'reason',
           render:rowData=> <div style={{ width: '100%', height: '100%', backgroundColor: isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?'#E2F6F9' : '', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 12}}>{rowData.status==='CANCELLED'&&rowData?.reason}</div>,
          cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:200,minWidth:200, height: 150, padding: openNewTable ? '0 5px' : '0'},
          headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300} },
        ]
        if(role==='shipper'){
            newColumn.unshift(
              { title: "Notification", field: 'notifyBroker', 
              render: rowData =>
                  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                     {isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length===0?
                       <div style={{ paddingLeft: 10}}>   </div>
                      :
   
                       <div >
                           <Tooltip title= {rowData?.notifyBroker?'Do not notify driver location through email':'Notify driver location through email'}>
                                <IconButton disabled={rowData.status !== 'REACHED'&& rowData.status !== 'STARTED'} onClick={()=>NotificationClicked(rowData)} style={{color:(rowData.status === 'REACHED' || rowData.status === 'STARTED')&&"red"}}>
                                    {rowData?.notifyBroker?<NotificationsActiveIcon/>  :<NotificationsOffIcon />} 
                                </IconButton>
                           </Tooltip>
                           
                        </div>
                      }
                  </div>,
             cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left',width:200,minWidth:200, height: 150, padding: openNewTable ? '0 5px' : '0'},
             headerStyle:{backgroundColor: pageBackgroundColor(),fontWeight: "bold",color: "white",fontSize: 15,width: 300,textAlign:"center"} 
            })
            
            setColumns(newColumn)

        }
        else setColumns(newColumn)
      }
  },[role,bookinglistdata.bookings,allBookingsWithSameLoadId,shippersBasicData,ActiveCompanyType])

  const NotificationClicked=(rowData)=>{
    dispatch(TrackingNotificationForBroker(rowData))
    dispatch(fetchBookings())
  }


const handleAsiignBooking=(rowData)=>{
    let userData={
        ...auth.info.profile,
        id:auth.info.uid
    }
    const itsSupperDispatcherOrItsAgentFromBroker = (isSuperDispatcher() && ActiveCompanyType==='broker') || (auth.info.profile.dispatcherId && ActiveCompanyType==='broker')
    const itsSupperDispatcherOrItsAgentFromCarrier = (isSuperDispatcher() && ActiveCompanyType==='carrier') || (auth.info.profile.dispatcherId && ActiveCompanyType==='carrier')
    
    if((role==='shipper' || auth.info.profile.brokerId|| itsSupperDispatcherOrItsAgentFromBroker)&&!rowData.assigndFromBroker){
        dispatch(AssignBooking({
          booking: rowData,
          adminId: userData,
          from:"broker"
        })).then((res) => {

          setTimeout(()=>{
            window.location.href = `/active-booking/${rowData.id}`;
          },300)
        }).catch((error) => {
        //  console.log('Error assigning booking:', error);
        });
    }
    else if (!rowData.assigndTo && (role === 'fleetadmin' || itsSupperDispatcherOrItsAgentFromCarrier || (role==='dispatcher'&&auth.info.profile.carrierId))) {
        dispatch(AssignBooking({
          booking: rowData,
          adminId: userData,
          from:"carrier"
        })).then((res) => {

          setTimeout(()=>{
            window.location.href = `/active-booking/${rowData.id}`;
          },300)
        }).catch((error) => {
          // console.log('Error assigning booking:', error);
        });
    }
    else {
       window.location.href = `/active-booking/${rowData.id}`;
    }

    
  }

  useEffect(()=>{
    dispatch(fetchProfile());
  },[dispatch,fetchProfile]);

  function compareBookingDates(a, b) {
    return b.bookingDate - a.bookingDate;
  }
  
  // Sort the array using the comparison function

 const[showedStatus,setShowedStatus]=useState("active")

 const [AllSharedCompanies,setAllSharedCompanies]=useState([])
 useEffect(()=>{
      if(isSuperDispatcher()){
          if(ActiveCompanyType==='carrier'){
              if(auth.info.profile.SharedCarriers&&auth.info.profile.SharedCarriers.length>0){
                  let allCarriers=[]
                  for(const carrier of auth.info.profile.SharedCarriers){
                    if(carrier.approved&&carrier.aprovedByCarrier){
                        const targetCarrier=userdata.users.filter(e=>{
                          return e.id===carrier.id
                        })
                        if(targetCarrier.length>0){
                              
                            allCarriers.push(
                              {
                                value: targetCarrier[0].id,
                                desc: `${targetCarrier[0].firstName} ${targetCarrier[0].lastName} (${targetCarrier[0]?.carrierData?.legalName||""} - ${targetCarrier[0]?.carrierData?.dotNumber||""})`,
                              }
                            )
                        }
        
                    }
                  }
                  setAllSharedCompanies(allCarriers)
              }
              else setAllSharedCompanies([])
          }
          else if(ActiveCompanyType==='broker'){
             if(auth.info.profile.SharedBrokers&&auth.info.profile.SharedBrokers.length>0){
                  let allBrokers=[]
                  for(const broker of auth.info.profile.SharedBrokers){
                    if(broker.approved&&broker.aprovedByBroker){
                        const targetBroker=userdata.users.filter(e=>{
                          return e.id===broker.id
                        })
                        if(targetBroker.length>0){
                              
                          allBrokers.push(
                              {
                                value: targetBroker[0].id,
                                desc: `${targetBroker[0].firstName} ${targetBroker[0].lastName}`,
                              }
                            )
                        }
        
                    }
                  }
                  setAllSharedCompanies(allBrokers)
             }
             else setAllSharedCompanies([])
          }

      }
      else if(auth.info?.profile?.dispatcherId){
        let SharedCarriers = giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers
        let SharedBrokers = giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedBrokers
        if(ActiveCompanyType==='carrier'&&SharedCarriers&&SharedCarriers.length>0){
          let allCarriers=[]
          for(const carrier of SharedCarriers){
            if(carrier.approved&&carrier.aprovedByCarrier){
                const targetCarrier=userdata.users.filter(e=>{
                  return e.id===carrier.id
                })
                if(targetCarrier.length>0){
                      
                    allCarriers.push(
                      {
                        value: targetCarrier[0].id,
                        desc: `${targetCarrier[0].firstName} ${targetCarrier[0].lastName} (${targetCarrier[0]?.carrierData?.legalName||""} - ${targetCarrier[0]?.carrierData?.dotNumber||""})`,
                      }
                    )
                }

            }
          }
          setAllSharedCompanies(allCarriers)
         }
        else if(ActiveCompanyType==='broker'&&SharedBrokers&&SharedBrokers.length>0){
          let allBrokers=[]
          for(const broker of SharedBrokers){
            if(broker.approved&&broker.aprovedByBroker){
                const targetBroker=userdata.users.filter(e=>{
                  return e.id===broker.id
                })
                if(targetBroker.length>0){
                      
                  allBrokers.push(
                      {
                        value: targetBroker[0].id,
                        desc: `${targetBroker[0].firstName} ${targetBroker[0].lastName}`,
                      }
                    )
                }

            }
          }
          setAllSharedCompanies(allBrokers)
        }
      }
 },[auth,ActiveCompanyType])

 const giveIdThenIwillGiveYouData=(id)=>{
     const targetUser =userdata.users.filter(e=>{
      return e.id===id
   })
     if(targetUser.length>0) return targetUser[0]
     return null

 }
  
  useEffect(()=>{
        if(bookinglistdata.bookings){
            if(isSuperDispatcher()|| auth.info?.profile?.dispatcherId){
                if(ActiveCompanyType==='broker'){
                    let SharedBrokers = auth.info?.profile?.dispatcherId ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedBrokers : auth.info?.profile?.SharedBrokers
                   
                    if(SharedBrokers && SharedBrokers.length>0){
                        let DispatcherBookingData=[]
                        for(const broker of SharedBrokers){
                            if(broker.approved&&broker.aprovedByBroker){
                              let dispatcherData=bookinglistdata.bookings.filter(e=>{
                                return e?.customer===broker.id &&  bookExistForUser(e)
                                })
                                DispatcherBookingData.push(...dispatcherData)
                            }
                          }
                        if(ActiveCompany) {
                          setData( DispatcherBookingData.filter((e)=>{
                              return e.customer===ActiveCompany.value
                          }))
        
                        }
                        else{
                          setData(DispatcherBookingData)
                        }
                    }
                    else setData([])
                }
                else{
                    let SharedCarriers = auth.info?.profile?.dispatcherId ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers : auth.info?.profile?.SharedCarriers
                    if(SharedCarriers && SharedCarriers.length>0){
                        let DispatcherBookingData=[]
                        for(const carrier of SharedCarriers){
                            if(carrier.approved&&carrier.aprovedByCarrier){
                              let dispatcherData=bookinglistdata.bookings.filter(e=>{
                                return e.carrierId===carrier.id &&  bookExistForUser(e)
                                })
                                DispatcherBookingData.push(...dispatcherData)
                            }
                        }
                        if(ActiveCompany) {
                          setData( DispatcherBookingData.filter((e)=>{
                              return e.carrierId===ActiveCompany.value
                          }))
        
                        }
                        else{
                          setData(DispatcherBookingData)
                        }
                    }
                    else setData([])
                }
                
            }
           else if(role==="shipper" || auth.info?.profile?.brokerId){
               let shipperData=bookinglistdata.bookings.filter(e=>{
                  return (auth.info?.profile?.brokerId? e.customer===auth?.info?.profile?.brokerId : e.customer===auth?.info?.uid) &&  bookExistForUser(e)
               })

              const uniqueData_e= [];
              const idMap = {};

              for(const shipper of shipperData){
                if (shipper.loadId === undefined || !idMap.hasOwnProperty(shipper.loadId)) {
                  uniqueData_e.unshift(shipper);
                  idMap[shipper.loadId] = true;
                }
              }
              
              setShippersBasicData(shipperData)
              uniqueData_e.sort(compareBookingDates)
              setData(uniqueData_e)
            }
            else if(role==="fleetadmin"){
                let fleetadminData=bookinglistdata.bookings.filter(e=>{
                    return e.carrierId===auth?.info?.uid &&  bookExistForUser(e)
                })
                setData(fleetadminData)
            }
            else if(role==="dispatcher"){
              let fleetadminData=bookinglistdata.bookings.filter(e=>{
                  return e.carrierId===auth?.info?.profile.carrierId &&  bookExistForUser(e) && (e.assigndTo === auth?.info?.uid || !e.assigndTo)
              })
              setData(fleetadminData)
          }
            else if(role==="driver"){
                let driverData=bookinglistdata.bookings.filter(e=>{
                    return e.carrierId===auth?.info?.profile?.fleetadmin &&  bookExistForUser(e)
                })
                setData(driverData)
            }

            else if(role==='admin'){
              setData(bookinglistdata.bookings)
            }
            
        }else{
          setData([]);
        }
  },[bookinglistdata.bookings,userdata,role,dispatchers,ActiveCompany]);


  function allLoadsAreCancelled(loadId){
    if(!shippersBasicData) return true
    let activeBookingOfLoadId = shippersBasicData.filter((e=>{
      return e.loadId===loadId && e.status!=='CANCELLED'
    }))

    return activeBookingOfLoadId.length===0
  }

  useEffect(()=>{
       if(data.length>0){
          if(showedStatus==='active'){
            let filteredData={}
            for(const each of data){
               if(!filteredData.hasOwnProperty(each.id)){
                  if(role==='shipper'){
                      if(each.loadId && !allLoadsAreCancelled(each.loadId) ){
                        filteredData[each.id]=each
                      }
                      else if(!each.loadId &&each.status!=='CANCELLED'){
                        filteredData[each.id]=each
                      }
                  }
                  else if(each.status!=='CANCELLED'){
                    filteredData[each.id]=each
                  }
                 
               }
            }
            setDataOnTable(Object.values(filteredData))

          }
          else{
            let filteredData={}
            for(const each of data){
              if(!filteredData.hasOwnProperty(each.id)){
                if(role==='shipper'){
                    if(each.loadId && allLoadsAreCancelled(each.loadId) ){
                      filteredData[each.id]=each
                    }
                    else if(!each.loadId&&each.status==='CANCELLED'){
                      filteredData[each.id]=each
                    }
                }
                else if(each.status==='CANCELLED'){
                  filteredData[each.id]=each
                }

              }
            }
            setDataOnTable(Object.values(filteredData))
          }
       }
       else{
        setDataOnTable([])
       }

  },[data,shippersBasicData,showedStatus])

  useEffect(() => {
    if(auth.info && auth.info.profile){
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);
  
  const onConfirmClose=(value)=>{
    if(value){

      const cancellledByAgent={
        usertype:auth.info.profile.usertype,
        id:auth.info.uid,
        time:new Date().getTime()
      }

      dispatch(cancelBooking({
        reason:value,
        booking:selectedBooking,
        cancelledBy: cancellledByAgent
      }));
      handleCloseNewTable()
    }
    setOpenConfirm(false);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

const handleCloseNewTable=()=>{
    setOpenNewTable(false)
    setAllBookingsWithSameLoadId([])
}
  
function giveMeIdThenIwillGiveYouName(id){
  if(!userdata||!userdata.staticusers) return null

  let targetUser=userdata.staticusers.filter(e=>{
     return e.id===id
  })
  if(targetUser.length===0) return null

  return targetUser[0].firstName+" "+targetUser[0].lastName

}

const AvailableStatus = [
  {label:'Awaiting full payment',value:'NEW'}, 
  {label:'Driver Waiting for pickup date',value:'ACCEPTED'}, 
  {label:'Driver arrived pickup location',value:'ARRIVED'}, 
  {label:'Driver headed to drop off location',value:'STARTED'}, 
  {label:'Driver reached drop off location',value:'REACHED'}, 
  {label:'Booking completed',value:'COMPLETE'}, 
  {label:'Booking Cancelled',value:'CANCELLED'}, 
]

const changeStatus=(status)=>{
  let assignedStatus =  AvailableStatus.filter(e=>{
    return e.value===status
  })
  if(assignedStatus.length === 0) return "Unknown"
  return assignedStatus[0].label
}


const [loading,setLoading] = useState(false)
const [checkOutNeedTransaction,setCheckOutNeedTransaction] = useState({processing:0,failed:0})

const checkTransactionReferencdes=()=>{
  setLoading(true)
  dispatch(api.checkBookingsTransaction())
  .then((res) => {
      setLoading(false)
      setCommonAlert({open:true,msg:res?.msg})
  })
  .catch(error=>{
     setLoading(false)
      setCommonAlert({open:true,msg:error?.message})
  })
}

const anyValidPaymentStatus = (payment,type) => {
  
  if (!payment) return false;

  if(type==="all"){
      return payment.status === 'failed' || payment.status === 'processing';
  }
  //  if(type==="failed") return payment.status === 'failed'
  return payment.status === type
};

useEffect(()=>{
    if(data && data.length>0){
         const filteredBooking = data.filter(booking=>{
              return  ["ACCEPTED", "NEW"].includes(booking.status) &&
                (anyValidPaymentStatus(booking.depositPayment,"all") || anyValidPaymentStatus(booking.fullPayment,'all')) &&
                booking.customBooking && booking.paymentStatus === 'unpaid'
         })
         if(filteredBooking.length>0){
              const processing = filteredBooking.filter(e=>{
                return anyValidPaymentStatus(e.depositPayment,"processing") || anyValidPaymentStatus(e.fullPayment,"processing") 
              }).length
              const failed = filteredBooking.filter(e=>{
                return anyValidPaymentStatus(e.depositPayment,"failed") || anyValidPaymentStatus(e.fullPayment,"failed") 
               }).length

              const filteredBookingObj={
                  processing:processing,
                  failed:failed,
              }
            setCheckOutNeedTransaction(filteredBookingObj)
          }

        }

   
},[data])


return (
    bookinglistdata.loading? <CircularLoading/>:
    <div style={{overflow: 'auto'}}>
        {(isSuperDispatcher() || auth.info.profile.dispatcherId)&&
         <Box>
          <Grid item xs={12} style={{width:"100%",display: ActiveCompany? '':'flex', flexDirection:"column",justifyContent:"center", alignItems: ActiveCompany?'':'center', height: ActiveCompany?'':'80vh',}}>
             <Box sx={{marginBottom:"15px"}}>
                <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Choose Comapny</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={ActiveCompanyType}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel value="carrier" control={<Radio />} label="Carrier" />
                        <FormControlLabel value="broker" control={<Radio />} label="Broker" />
                      </RadioGroup>
                  </FormControl>
             </Box>

              {AllSharedCompanies.length>0 ?
                <UsersCombo
                  className={classes.items}
                  placeholder={`${ActiveCompanyType==='carrier'?'Carrier':"Broker"} Companies That Shared Their Dashboard`}
                  users={AllSharedCompanies}
                  value={ActiveCompany}
                  onChange={(event, newValue) => {
                    SetActiveCompany(newValue)}}
                  style={{width: ActiveCompany?'':'100%'}}
                />
                : 
                <div style={{textAlign:"center",width:"100%"}}>
                  <Typography variant="h4" >No Company found </Typography>
                  <div>make a relation with {ActiveCompanyType==='carrier'?<Link href="/my-carriers">carrier</Link>:<Link href="/my-brokers">broker</Link> }</div>
                </div>
                }

             
            </Grid> 

         </Box>
        }  

        

        { (isSuperDispatcher() || auth.info.profile.dispatcherId) && !ActiveCompany ? <></> : data.length>0&&<div style={{textAlign:"center"}}>
          <FormControl >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="active"
                name="radio-buttons-group"
                row
                value={showedStatus}
                onChange={(event)=>setShowedStatus(event.target.value)}
              >
                <FormControlLabel value="cancelled" control={<Radio />} label="Cancelled" />
                <FormControlLabel value="active" control={<Radio />} label="Active" />
              </RadioGroup>
          </FormControl>
         </div>}

         {/* {(auth?.info?.profile?.usertype === 'fleetadmin' || auth?.info?.profile?.carrierId)&& isSpecialAdmin()  &&
         <Box sx={{textAlign:"end",margin:"6px 0 10px 0"}}>
             {loading?
              <CircularProgress size={35} style={{fontWeight:700,color:pageBackgroundColor()}}/>
              :
              <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"center",gap:3}}> 
                 <Box sx={{display:"flex",justifyContent:"flex-end",alignItems:"center",gap:1}}>
                      <Typography style={{fontSize:"13px"}}>Processing <span style={{color:"green",fontWeight:700}}>({checkOutNeedTransaction.processing})</span></Typography>
                      <Typography style={{fontSize:"13px"}}>Failed  <span style={{color:"red",fontWeight:700}}>({checkOutNeedTransaction.failed})</span></Typography>
                 </Box>
                <Button onClick={checkTransactionReferencdes} style={{background:pageBackgroundColor(),color:"white"}}>Checkout Transactions</Button>
              </Box>
             }
        </Box>} */}

        { (isSuperDispatcher() || auth.info.profile.dispatcherId) && !ActiveCompany ?  <></> : 
        <MaterialTable
        title={t("load_history")}
        columns={columns}
        data={dataOnTable}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true
        }}
        localization={{
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          header: {
            actions: (
              <div style={{backgroundColor: pageBackgroundColor(), padding: '45px 30px 45px 30px', fontWeight: "bold",color: "white",fontSize: 15,width:"200px",lineBreak:"anywhere"}}>
                {t('booking_status')}
              </div>
            ) 
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          labelRowsSelect: (t('rows')),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
        actions={[
          rowData => ({
            icon: () => 
             <div style={{fontWeight:"700",display: 'flex',alignItems: 'center',flexWrap: 'wrap',flexDirection:"column",fontSize:"14px",gap:"5px",lineBreak:"auto",width:"150px"}}>
                {
                  role === 'shipper' && isBookMultiple(rowData?.loadId)&&allBookingsWithSameLoadId.length === 0 ? 
                  <div>  </div> :
                  (rowData?.approved===false&&rowData.status==="NEW")?
                  <span >Booking Not Approved</span>  
                : 
                  <>
                    {rowData.status==='NEW' || rowData.status==='ACCEPTED'?
                      <>
                        <div style={{color:"green"}}>{rowData.status==='NEW'? !rowData?.loadImage?"Waiting for collection of item": 
                          rowData?.fullPayment?.status ==="verified"? "Waiting for the driver to be done": changeStatus(rowData.status) :
                           changeStatus(rowData.status)}</div>
                      </>
                      :
                      <span >
                          <div>{changeStatus(rowData.status)}</div>
                          <div style={{width:"150px",wordBreak:"break-word",lineBreak:"anywhere"}}>
                              {rowData.status==='CANCELLED'?
                                  ` by ${
                                        rowData?.cancelledBy?.usertype? 
                                      `${rowData?.cancelledBy?.usertype} ${giveMeIdThenIwillGiveYouName(rowData?.cancelledBy.id)} 
                                        ${rowData.cancelledBy?.time?"at "+moment(rowData.cancelledBy?.time).format("lll"):''}`
                                        :
                                      rowData?.cancelledBy
                                        }`
                                  :null}

                            </div>
                    </span>
                    }
                  </>
                }
                
            </div>,
            disabled:true,
          }),
        ]}
        style={{width: 3300, overflowX: 'auto'}}
      />}
        
        <ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />

          <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    
          <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={dispatcherModal}
              onClose={handleCloseDispatcherManagement}
              className={classes.modal}
              container={() => rootRef.current}
            >
              <Box spacing={1} className={classes.paper}>

                    <h2 style={{textAlign:"center"}}>Authorized Users</h2>
                    {dispatchers.length>0?
                    <div style={{maxHeight: '60vh', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                          {dispatchers.map((e,index)=>{
                            return(
                              <div onClick={()=>changeDispatcher(e)} key={index} style={{cursor:"pointer", padding: '10px 15px'}} className={classes.eachDispatcher}>
                                  <div style={{fontWeight:"600"}}>{e.firstName} {e.lastName}</div>
                                  <div>{e.email}</div>
                                  {e.workingRoute&&e.workingRoute.length>0&&
                                    <div style={{fontWeight:600,padding:4,borderRadius:20,marginBottom:3,fontSize:13,background:pageBackgroundColor(),color:"white",textAlign:"center"}}>
                                      {e.workingRoute.join(", ")}
                                  </div>}
                                  {e.independent&&<div style={{fontSize:"11px",color:"red",padding:"6px 0 0 6px"}}>Independent</div>}
                              </div>
                            )
                          })}

                    </div>
                    :
                    <div style={{textAlign:"center"}}>No Authorized user found</div>
                    }
                    {loadingWhileAssigning&&<div style={{marginTop:"5px",textAlign:"center"}}><CircularProgress/></div>}
                    {errorWhileAssigning&&<div style={{marginTop:"8px",color:"red",textAlign:"center"}}>{errorWhileAssigning}</div>}


              </Box>
            </Modal>

            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={openNewTable}
              onClose={handleCloseNewTable}
              className={classes.modal}
              container={() => rootRef.current}
              style={{ display:"flex",justifyContent:"flex-start",flexDirection:"column",height:"85vh",marginTop:"40px",overflow:"auto" }}            
            >
              <Box spacing={1} className={classes.paper}>
                    <MaterialTable
                          title={t("load_history")}
                          columns={columns}
                          data={allBookingsWithSameLoadId}
                          options={{
                            exportButton: settings.AllowCriticalEditsAdmin,
                            sorting: true
                          }}
                          localization={{
                            toolbar: {
                              searchPlaceholder: (t('search')),
                              exportTitle: (t('export')),
                            },
                            header: {
                              actions: (
                                <div style={{backgroundColor: pageBackgroundColor(), padding: '45px 30px 45px 30px', fontWeight: "bold",color: "white",fontSize: 15,width:"200px",lineBreak:"anywhere"}}>
                                {t('booking_status')}
                                </div>
                              ) 
                          },
                          pagination: {
                            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
                            labelRowsSelect: (t('rows')),
                            firstTooltip: (t('first_page_tooltip')),
                            previousTooltip: (t('previous_page_tooltip')),
                            nextTooltip: (t('next_page_tooltip')),
                            lastTooltip: (t('last_page_tooltip'))
                          },
                        }}
                          actions={[
                            rowData => ({
                              icon: () => 
                              <div style={{fontWeight:"700",display: 'flex',alignItems: 'center',flexWrap: 'wrap',flexDirection:"column",fontSize:"14px",gap:"5px",lineBreak:"auto",width:"150px"}}>
                                  {(rowData?.approved===false&&rowData.status==="NEW")?
                                    <span style={{width:"150px"}}>Booking Not Approved</span>  
                                  : 
                                  <>
                                    {rowData.status==='NEW' || rowData.status==='ACCEPTED'?
                                      <>
                                        <div style={{color:"green",width:"150px"}}>{changeStatus(rowData.status)}</div>
                                      </>
                                      :
                                      <span >
                                          <div>{changeStatus(rowData.status)}</div>
                                          <div style={{width:"150px",wordBreak:"break-word",lineBreak:"anywhere"}}>
                                              {rowData.status==='CANCELLED'?
                                                  ` by ${
                                                        rowData?.cancelledBy?.usertype? 
                                                      `${rowData?.cancelledBy?.usertype} ${giveMeIdThenIwillGiveYouName(rowData?.cancelledBy.id)} 
                                                        ${rowData.cancelledBy?.time?"at "+moment(rowData.cancelledBy?.time).format("lll"):''}`
                                                        :
                                                      rowData?.cancelledBy
                                                        }`
                                                  :null}

                                            </div>
                                        </span>
                                    }
                                </>
                                }
                                  
                              </div>,
                              disabled:true,
                            }),
                          ]}
                          style={{width: 3300, overflowX: 'auto'}}
                        />

              </Box>
            </Modal>

            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={isSureToDelete.open}
              onClose={()=>HandleIsSureToDelete({open:false,book:null})}
              className={classes.modal}
              container={() => rootRef.current}
            >
              <Box spacing={1} className={classes.paper}>
                      <h3 >Delete delivery with code {isSureToDelete?.book?.reference} ?</h3>
                      <div >
                        Please note that deleting this booking means you will lose access to the booking information permanently, 
                        unless you reach out to our support team for assistance.
                      </div>

                      <div style={{marginTop:"7px"}}>
                          <Button style={{color:"red"}} onClick={deleteBooking}>Go on</Button>
                          <Button style={{color:pageBackgroundColor()}} onClick={()=> HandleIsSureToDelete({open:false,book:null})}>Back</Button>
                      </div>

                 </Box>

            </Modal>

           
    </div>

  );
}

export default BookingHistory;
/**
 * "emulators": {
    "auth": {
      "port": 9099
    },
    "functions": {
      "port": 5001
    },
    "firestore": {
      "port": 8080
    },
    "database": {
      "port": 9000
    },
    "hosting": {
      "port": 5000
    },
    "pubsub": {
      "port": 8085
    },
    "storage": {
      "port": 9199
    },
    "eventarc": {
      "port": 9299
    },
    "ui": {
      "enabled": true
    },
    "singleProjectMode": true
  }
 */